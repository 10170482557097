import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../../../services/firebase';
import { withRouter } from 'react-router-dom';
import { Table, Popconfirm, Row, Col, DatePicker, Input } from 'antd';
import loader from '../../../../assets/imgs/loader.gif';
import { saveAs } from 'file-saver';
import { fileAttachment } from '../../../../services/auth';
import Moment from 'react-moment';
import * as moment from 'moment';
import { addActivityLogs, isEmpty } from '../../../../utils/activity-logs';
import { checkDeletePermissions, checkWritePermissions } from '../../../../utils/native';

const { Column } = Table;

class LoadReportList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'reports'),
			isDeletedPermitted: checkDeletePermissions(props, 'reports'),
			isLoading: false,
			reports: [],
			reportsLoadMore: false,
			rigs: [],
			searchInput: '',
			searchList: [],
			rigdown_notes: [],
			rigdown_dates: [],
			rigup_notes: [],
			rigup_dates: [],
			users: props.auth.usersList,
			isAdmin: localStorage.getItem('admin') === 'true' ? true : false
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'reports'), isDeletedPermitted: checkDeletePermissions(this.props, 'reports') });
		}
		const { usersList } = this.props.auth;
		if (prevProps.auth.usersList.length !== usersList.length) {
			this.setState({ users: usersList });
		}
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		this.getRigs();
	}

	getRigs() {
		const { rigsList } = this.props.rig;
		this.setState({ rigs: rigsList }, () => {
			this.getReports();
		});
	}

	getReports() {
		const { rigs } = this.state;
		const db = firebase.firestore();
		const reportsRef = db.collection('rigReports').where('reportType', '==', 'load_list_report').orderBy('generatedOn', 'desc');
		this.setState({ isLoading: true });
		reportsRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						const userIndex = rigs.map(admin => admin.$key).indexOf(data.rigId);
						data.rigName = rigs[userIndex].name;
						data.rigNumber = rigs[userIndex].number;
						data.rigJobNumber = rigs[userIndex].jobNumber;
						data.rigLoadcount = rigs[userIndex].loadcount;
						data.moveType = rigs[userIndex].moveType;
						data.status = rigs[userIndex].status;
						if (rigs[userIndex].loadcount && rigs[userIndex].loadcount > 0) {
							if (isEmpty(data.number)) {
								temp.push(data);
							}
						}
						this.getUserDetails(data);
					}
				}
			});
			this.setState({ reports: temp, isLoading: false });
		});
	}

	getUserDetails(data) {
		const { users } = this.state;
		const userIndex = users.map(user => user.uid).indexOf(data.uid);
		if (userIndex !== -1) {
			data.updatedByUser = users[userIndex].firstName + ' ' + users[userIndex].lastName;
		}
	}

	fetchCreatedByUser(uid) {
		const { users } = this.state;
		return new Promise((resolve) => {
			const userIndex = users.map(user => user.uid).indexOf(uid);
			if (userIndex !== -1) {
				resolve(users[userIndex].firstName + ' ' + users[userIndex].lastName);
			}
		});
	}

	/* ---------------------------- RigDown Notes ---------------------------- */
	getRigDownNotes(report) {
		return new Promise((resolve) => {
			const { rigdown_dates } = this.state;
			const selectedDate = moment(report.date).format('YYYY-MM-DD');
			
	
			const database = firebase.firestore();
			if (!report.endDate) {
				const rigs = database.collection('Rig-Down').where('rigId', '==', report.rigId).where('date', '==', selectedDate).orderBy('time', 'asc');
				rigs.get().then((querySnapshot) => {
					const temp = [];
					querySnapshot.forEach(async (doc) => {
						const data = doc.data();
						data.$key = doc.id;
						if (doc.exists) {
							data.createdByUser = !isEmpty(data.createdBy) ? await this.fetchCreatedByUser(data.createdBy) : '';
							data.updatedByUser = !isEmpty(data.updatedBy) ? await this.fetchCreatedByUser(data.updatedBy) : '';
							temp.push(data);
						}
					});
					this.setState({
						rigdown_notes: temp
					});
					resolve(true);
				});
			} else {
				const rigs = database.collection('Rig-Down').where('rigId', '==', report.rigId).where('date', '>=', selectedDate).where('date', '<=', report.endDate);
				rigs.get().then((querySnapshot) => {
					const temp = [];
					querySnapshot.forEach(async (doc) => {
						const data = doc.data();
						data.$key = doc.id;
						if (doc.exists) {
							data.createdByUser = !isEmpty(data.createdBy) ? await this.fetchCreatedByUser(data.createdBy) : '';
							data.updatedByUser = !isEmpty(data.updatedBy) ? await this.fetchCreatedByUser(data.updatedBy) : '';
							temp.push(data);
							rigdown_dates.push(data.date);
						}
					});
					this.setState({
						rigdown_notes: temp,
						rigdown_dates: rigdown_dates.filter((item, index, inputArray) => {
							return inputArray.indexOf(item) === index;
						})
					});
					rigdown_dates.sort((a, b) => (a.time > b.time) ? -1 : 1);
					resolve(true);
				});
			}
		});
	}

	/* ---------------------------- RigUp Notes ---------------------------- */
	getRigUPNotes(report) {
		return new Promise((resolve) => {
			const { rigup_dates } = this.state;
			const selectedDate = moment(report.date).format('YYYY-MM-DD');
	
			const database = firebase.firestore();
			if (!report.endDate) {
				const rigs = database.collection('Rig-Up').where('rigId', '==', report.rigId).where('date', '==', selectedDate).orderBy('time', 'asc');
				rigs.get().then((querySnapshot) => {
					const temp = [];
					querySnapshot.forEach(async (doc) => {
						const data = doc.data();
						data.$key = doc.id;
						if (doc.exists) {
							data.createdByUser = !isEmpty(data.createdBy) ? await this.fetchCreatedByUser(data.createdBy) : '';
							data.updatedByUser = !isEmpty(data.updatedBy) ? await this.fetchCreatedByUser(data.updatedBy) : '';
							temp.push(data);
						}
					});
					this.setState({
						rigup_notes: temp
					});
					resolve(true);
				});
			} else {
				const rigs = database.collection('Rig-Up').where('rigId', '==', report.rigId).where('date', '>=', selectedDate).where('date', '<=', report.endDate);
				rigs.get().then((querySnapshot) => {
					const temp = [];
					querySnapshot.forEach(async (doc) => {
						const data = doc.data();
						data.$key = doc.id;
						if (doc.exists) {
							data.createdByUser = !isEmpty(data.createdBy) ? await this.fetchCreatedByUser(data.createdBy) : '';
							data.updatedByUser = !isEmpty(data.updatedBy) ? await this.fetchCreatedByUser(data.updatedBy) : '';
							temp.push(data);
							rigup_dates.push(data.date);
						}
					});
					this.setState({
						rigup_notes: temp,
						rigup_dates: rigup_dates.filter((item, index, inputArray) => {
							return inputArray.indexOf(item) === index;
						})
					});
					rigup_dates.sort((a, b) => (a.time > b.time) ? -1 : 1);
					resolve(true);
				});
			}
		});
	}

	reportsLoadMoreData() {		
		const db = firebase.firestore();
		const reportsRef = db.collection('rigReports').orderBy('generatedOn', 'desc').startAt(this.state.reports[this.state.reports.length - 1].generatedOn).limit(25);
		reportsRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
					}
				}
			});
			this.setState({ reports: [...this.state.reports, ...temp] });
		});
	}

	getRigById(rigId) {
		return new Promise((resolve) => {
			const { rigsList } = this.props.rig;
			const temp = rigsList.find((item) => item.$key === rigId);
			this.setState({ rig: temp });
			resolve(true);
		});
	}

	viewReport(key) {
		this.props.history.push(`/load_report/${key}`);
	}

	handleDownload(selectedReport) {
		this.setState({ isLoading: true });
		fileAttachment(selectedReport)
			.then((res) => {
				saveAs(res.data.file, selectedReport.title);
				this.setState({ isLoading: false });
			}).catch(() => {
			});
	}

	handleUpdate(key) {
		this.props.history.push(`/report/load_report/${key}`);
	}

	async handleDelete(report) {
		const db = firebase.firestore();
		const reportsRef = await db.collection('rigReports').where('$key', '==', report.$key);
		reportsRef.get().then((querySnapshot) => {
			querySnapshot.forEach(async (doc) => {
				if (doc.exists) {
					// await db.collection('rigReports').doc(doc.id).delete();
					report.archive = true;
					await db.collection('rigReports').doc(doc.id).update(report);
					await this.getRigById(report.rigId);
					this.updateLogs();
					this.getReports();
				}
			});
		});
	}

	updateLogs = async() => {
		const { rig } = this.state;
		await addActivityLogs(rig, 'delete', 'Load List Report', rig.$key)
			.then(() => {
				alert('Report is deleted successfully');
			});
	}

	reportSearch(value) {
		const { reports } = this.state;
		this.setState({ searchInput: value });
		const string = value.toLowerCase();
		const temp = [];
		if (value !== '') {
			for (let i = 0; i < reports.length; i++) {
				const data = (reports[i].title !== undefined) && (reports[i].title.toLowerCase().match(string) || reports[i].rigName.toLowerCase().match(string) ||
					reports[i].rigNumber.toLowerCase().match(string) || reports[i].rigJobNumber.toLowerCase().match(string));
				if (data !== null && Array.isArray(data)) {
					temp.push(reports[i]);
				}
			}
			this.setState({ searchList: temp });
		}
	}

	fromDate(date) {
		const { to } = this.state;
		if (!isEmpty(date)) {
			this.setState({ from: moment(date).format('YYYY-MM-DD') });
			const database = firebase.firestore();
			if (!isEmpty(to)) {
				const reportRef = database.collection('rigReports').where('date', '>=', moment(date).format('YYYY-MM-DD')).where('date', '<=', to).orderBy('date', 'desc');
				this.searchReports(reportRef);
			} else {
				const reportRef = database.collection('rigReports').where('date', '>=', moment(date).format('YYYY-MM-DD')).orderBy('date', 'desc');
				this.searchReports(reportRef);
			}
		} else {
			this.setState({ from: '', isLoading: true });
			this.getReports();
		}
	}

	toDate(date) {
		const { from } = this.state;
		if (date !== null && !isEmpty(from)) {
			if (from < moment(date).format('YYYY-MM-DD')) {
				this.setState({ to: moment(date).format('YYYY-MM-DD') });
			}
			const database = firebase.firestore();
			const reportRef = database.collection('rigReports').where('date', '>=', from).where('date', '<=', moment(date).format('YYYY-MM-DD')).orderBy('date', 'desc');
			this.searchReports(reportRef);
		}

		if (date === null && isEmpty(from)) {
			this.getReports();
		}
		if (date === null && !isEmpty(from)) {
			this.fromDate(from);
		}
	}

	searchReports(reportRef) {
		const { rigs } = this.state;
		reportRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true && data.reportType === 'load_list_report') {
						const userIndex = rigs.map(admin => admin.$key).indexOf(data.rigId);
						data.rigName = rigs[userIndex].name;
						data.rigNumber = rigs[userIndex].number;
						data.rigJobNumber = rigs[userIndex].jobNumber;
						data.rigLoadcount = rigs[userIndex].loadcount;
						data.moveType = rigs[userIndex].moveType;
						data.status = rigs[userIndex].status;
						if (rigs[userIndex].loadcount && rigs[userIndex].loadcount > 0) {
							if (isEmpty(data.number)) {
								temp.push(data);
							}
						}
					}
				}
			});
			this.setState({ reports: temp });
		});
	}

	async downloadLoadReport(report) {
		await this.getRigDownNotes(report);
		await this.getRigUPNotes(report);
		const { rigdown_notes, rigup_notes } = this.state;


		const notes = rigdown_notes.concat(rigup_notes);
		notes.sort((a, b) => (a.time > b.time) ? -1 : 1);
		const data = [];
		const header = [
			// Title of the columns (column_names)
			'Load #', 'Date & Time', 'Created By', 'Updated By', 'Company', 'Truck #', 'Driver Name', 'Load Type', 'Rig Part', 'Non-Permit Load', 'Permit Load', 
			'Width', 'Height', 'Length', 'Total Overhang', '# Tractor Axles', '# Trailer Axles', '# Stinger Axles', '# Jeep Axles'
		];
		const headerDetails = ['', report.rigName, report.rigName];
		const headerDetails1 = ['Job #', report.rigJobNumber !== undefined ? report.rigJobNumber : '', '', 'Load Count', report.rigLoadcount !== undefined ? report.rigLoadcount : ''];

		data.push(headerDetails);
		data.push(headerDetails1);
		data.push(header);


		for (let i = 0; i < notes.length; i++) {
			notes[i].csvPart = '';
			if (notes[i].part !== undefined) {
				for (let j = 0; j < notes[i].part.length; j++) {				
					notes[i].csvPart += notes[i].part[j].name + '/';
				}
			}
		}

		notes.forEach(async (element) => {
			const temp = {
				'load': notes.indexOf(element) + 1,
				'dateTime': moment(element.time).format('MMM DD YYYY HH:mm'),
				'createdBy': element.createdBy !== undefined ? element.createdByUser : '',
				'updatedBy': element.updatedBy !== undefined ? element.updatedByUser : '',
				'company': element.companyName !== undefined ? element.companyName : '',
				'truckNo': element.truck !== undefined ? element.truck : '',
				'driverName': element.driverName !== undefined ? element.driverName : '',
				'load_type': element.load_type !== undefined ? element.load_type : '',
				'part': element.csvPart,
				'non-permit': element.permitLoad === false ? '✔️' : '',
				'permit': element.permitLoad === true ? '✔️' : '',
				'width': (element.dimensions.width.feet !== undefined ? `${element.dimensions.width.feet} ft.` : '') + (element.dimensions.width.inches !== undefined ? `${element.dimensions.width.inches} inch.` : ''),
				'height': (element.dimensions.height.feet !== undefined ? `${element.dimensions.height.feet} ft.` : '') + (element.dimensions.height.inches !== undefined ? `${element.dimensions.height.inches} inch.` : ''),
				'length': (element.dimensions.length.feet !== undefined ? `${element.dimensions.length.feet} ft.` : '') + (element.dimensions.length.inches !== undefined ? `${element.dimensions.length.inches} inch.` : ''),
				'truck': (element.dimensions.truck.feet !== undefined ? `${element.dimensions.truck.feet} ft.` : '') + (element.dimensions.truck.inches !== undefined ? `${element.dimensions.truck.inches} inch.` : ''),
				'tractorAxles': element.tractorAxles !== undefined ? element.tractorAxles : '',
				'axles': element.axles !== undefined ? element.axles : '',
				'stingerAxles': element.permitLoad === false ? '-' : element.stingerAxles !== undefined ? element.stingerAxles : '',
				'jeepAxles': element.permitLoad === false ? '-' : element.jeepAxles !== undefined ? element.jeepAxles : ''
			};
			data.push(temp);
		});
		this.convertToCSV(data, report);
	}

	convertToCSV(objArray, report) {
		var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
		var str = '';

		for (var i = 0; i < array.length; i++) {
			var line = '';
			for (var index in array[i]) {
				if (line !== '') line += ',';

				line += array[i][index];
			}

			str += line + '\r\n';
		}
		const blob = new Blob([str], { type: 'text/csv' });
		saveAs(blob, report.title);
		return str;
	}

	render() {
		const { reports, isLoading, searchInput, searchList, isAdmin, isPermitted, isDeletedPermitted } = this.state;
		const { Search } = Input;

		if (isLoading === true) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}

		return (
			<div>
				<div className="card-body">
					<Row className="rowContent">
						<Col span={12} className="searchInput spacing-top">
							<Search
								placeholder="Enter Rig Name, Rig Number, Rig Job Number, Title"
								className="rigSearch custom-search"
								allowClear={true}
								onChange={(data) => this.reportSearch(data.target.value)}
							/>
						</Col>
						<Col className="custom-date-picker spacing-bottom" span={6} id="DropdownFixPostion">
							<span className="small-title">From:</span>
							<DatePicker
								getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
								onChange={(value) => this.fromDate(value)}
								className="date_picker"
							/>
						</Col>
						<Col className="custom-date-picker spacing-bottom" span={6} id="DropdownFixPostion">
							<span className="small-title">To:</span>
							<DatePicker
								getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
								onChange={(value) => this.toDate(value)}
								className="date_picker"
							/>
						</Col>
					</Row>
					{reports.length > 0 ?
						<Table dataSource={searchInput === '' ? reports : searchList} rowKey={(record, index) => record.uid + index} pagination={{ defaultPageSize: 10, showSizeChanger: false }}>
							<Column title='Rig Name' dataIndex='rigName' key='rigName' />
							<Column title='Rig Number' dataIndex='rigNumber' key='rigNumber' />
							<Column title='Rig Job Number' dataIndex='rigJobNumber' key='rigJobNumber' />
							<Column title='Title' dataIndex='title' key='title' />
							<Column title='Load Count' dataIndex='rigLoadcount' key='rigLoadcount' />
							<Column title='Status' dataIndex='status' key='status' />
							<Column title="Date & Time" dataIndex="generatedOn" render={(record) => <Moment format="MMM DD, YYYY HH:mm">{record}</Moment>} />
							<Column title='Created By' dataIndex='updatedByUser' key='updatedByUser' />
							<Column title='Move Type' dataIndex='moveType' key='moveType' />
							<Column title="View" dataIndex="View" render={(_, record) =>
								this.state.reports.length >= 1 ? (
									<p className="table-action-link box-link" onClick={() => this.viewReport(record.$key)}>View</p>
								) : null}
							/>
							{/* <Column title="Download" dataIndex="download"
								render={(_, record) =>
									<Popconfirm overlayClassName="custom-popconfirm" disabled={!record.url} title="Are you sure to download this report?" onConfirm={() => this.handleDownload(record)}>
										<a href="true" style={record.url ? { color: '#1890ff' } : { color: '#8d8c8c' }}>Download</a>
									</Popconfirm>
								}
							/> */}
							<Column title="Download" dataIndex="download"
								render={(_, record) =>
									<Popconfirm overlayClassName="custom-popconfirm" title="Are you sure to download excel for this report?" onConfirm={() => this.downloadLoadReport(record)}>
										<a href="true">Generate Excel</a>
									</Popconfirm>
								}
							/>
							{isAdmin === true && isPermitted &&
								<Column title="Edit" dataIndex="Edit" render={(_, record) =>
									this.state.reports.length >= 1 ? (
										<Popconfirm overlayClassName="custom-popconfirm" title="Are you sure to update this report?" onConfirm={() => this.handleUpdate(record.$key)}>
											<a href="true">Edit</a>
										</Popconfirm>
									) : null}
								/>
							}
							{isAdmin === true && isDeletedPermitted &&
								<Column title="Delete" className="delete" dataIndex="Delete" render={(_, record) =>
									this.state.reports.length >= 1 ? (
										<Popconfirm overlayClassName="custom-popconfirm" title="Are you sure to delete this rig ?" onConfirm={() => this.handleDelete(record)}>
											<a href="true">Delete</a>
										</Popconfirm>
									) : null}
								/>
							}
						</Table>
						:
						<div className="card-body">
							<h3 className="card-title text-center">No reports are found</h3>
						</div>
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer,
	rig: state.rigReducer
});

export default connect(mapStateToProps)(withRouter(LoadReportList));