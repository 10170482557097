import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, DatePicker, Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import firebase from '../../../services/firebase';
import { BiArrowBack } from 'react-icons/bi';
import loader from '../../../assets/imgs/loader.gif';
import { addActivityLogs } from '../../../utils/activity-logs';
import { checkWritePermissions } from '../../../utils/native';

class EditRigOptional extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(this.props, 'rigs'),
			assets: [],
			data: [],
			load: false,
			validateTrigger: false,
			id: props.match.params.id,
			rig: {},
			Loader: false,
			currentUser: props.auth.currentUser,
			isAddRoute: props.history.location.state?.add
		};
	}

	componentDidMount() {
		this.getSelectedRig();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'rigs') });
		}
	}

	getSelectedRig() {		
		this.setState({ Loader: true });
		const { rigsList } = this.props.rig;
		const temp = rigsList.find((item) => item.$key === this.state.id);
		this.setState({ rig: {...temp, releaseDate: temp.releaseDate.replace('Z', '')}, Loader: false });
	}

	onInputChange(key, value) {
		const { rig } = this.state;
		rig[key] = value;
		this.setState({ ...rig });

		if (rig.estDays && rig.actualDaysOfRigmove && rig.estDays !== '' && rig.actualDaysOfRigmove !== '') {
			rig.estimatedVsActual = rig.actualDaysOfRigmove - rig.estDays;
		} else {
			rig.estimatedVsActual = 0;
		}


		if (rig.actualDaysOfRigmove && rig.actualDaysOfRigmove !== '') {
			if ((rig.NptHours) && (rig.NptHours !== 0)) {
				rig.WoNptDays = rig.actualDaysOfRigmove - rig.NptDays;
				rig.WoNptHours = 24 - rig.NptHours;

				if (((rig.NptDays === 0) || (!rig.NptDays)) && (rig.NptHours !== 0)) {
					rig.NptDays = 0;
					rig.WoNptDays = rig.actualDaysOfRigmove;
					rig.WoNptHours = 24 - rig.NptHours;
				}
				if (rig.WoNptDays >= 1) {
					rig.WoNptDays -= 1;
				}
				if ((rig.WoNptDays > 0) && (rig.WoNptDays < 1)) {
					rig.WoNptHours = 0;
					rig.WoNptDays += 1;
				}
			} else {
				rig.WoNptDays = rig.actualDaysOfRigmove - rig.NptDays;
				rig.WoNptHours = 0;
			}
		}
		if ((rig.NptHours === 0)) {
			rig.NptHours = 0;
			rig.WoNptHours = 0;
		}
		if ((rig.NptDays === 0) && (rig.NptHours === 0)) {
			rig.NptDays = 0;
			rig.WoNptDays = 0;
			rig.NptHours = 0;
			rig.WoNptHours = 0;
		}
	}

	onChange(key, value) {
		const { rig } = this.state;
		if (value !== null) {
			rig[key] = moment(value).format('YYYY-MM-DDTHH:mm:ss');
	
	
			if (rig.beginBope && rig.finishBope && rig.beginBope !== '' && rig.finishBope !== '') {
				const begin = moment(rig.beginBope);
				const finish = moment(rig.finishBope);
				rig.totalBopeDays = finish.diff(begin, 'days');
				rig.totalBopeHours = finish.diff(begin, 'hours') - (rig.totalBopeDays * 24);
			}
	
			if (rig.mastRaised && rig.pickupBha && rig.mastRaised !== '' && rig.pickupBha !== '') {
				const mast = moment(rig.mastRaised);
				const pickup = moment(rig.pickupBha);
				rig.mastPickupDays = pickup.diff(mast, 'days');
				rig.mastPickupHours = pickup.diff(mast, 'hours') - (rig.mastPickupDays * 24);
			} else {
				rig.mastPickupDays = 0;
				rig.mastPickupHours = 0;
			}
	
			if (rig.bumpPlug && rig.pickupBha && rig.bumpPlug !== '' && rig.pickupBha !== '') {
				const bump = moment(rig.bumpPlug);
				const pickup = moment(rig.pickupBha);
				rig.plugBumpDays = pickup.diff(bump, 'days');
				rig.plugBumpHours = pickup.diff(bump, 'hours') - (rig.plugBumpDays * 24);
			} else {
				rig.plugBumpDays = 0;
				rig.plugBumpHours = 0;
			}
	
			if (rig.releaseDate && rig.pickupBha && rig.releaseDate !== '' && rig.pickupBha !== '') {
				const release = moment(rig.releaseDate);
				const pickup = moment(rig.pickupBha);
				rig.releasePickupDays = pickup.diff(release, 'days');
				rig.releasePickupHours = pickup.diff(release, 'hours') - (rig.releasePickupDays * 24);
			} else {
				rig.releasePickupDays = 0;
				rig.releasePickupHours = 0;
	
			}
		} else {
			rig[key] = '';
		}
		this.setState({ ...rig });
	}

	handleSubmit(e) {
		e.preventDefault();
		const { form } = this.props;
		const { rig, isAddRoute } = this.state;
		
		form.validateFields(async (err) => {
			this.setState({ validateTrigger: true });
			if (!err) {
				this.setState({
					errorMessage: ''
				});

				const db = firebase.firestore();
				await db.doc(`rigs/${this.state.id}`).update(rig)
					.then(() => {
						if(isAddRoute !== true) {
							this.updateLogs();
						} else {
							this.props.history.push('/rigs');
						}
					})
					.catch(() => {});
			}
		});
	}

	async updateLogs() {
		const { rig } = this.state;

		rig.updatedAt = moment().valueOf();
		rig.updatedBy = localStorage.getItem('user');
		await addActivityLogs(rig, 'update', 'rigs', rig.$key).then(() => {
			this.props.history.push('/rigs');
		});
	}

	render() {
		const { validateTrigger, rig, Loader } = this.state;
		const { form: { getFieldDecorator } } = this.props;

		if (Loader === true) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}

		return (
			<div className="edit-rig-area edit-rig-asset-part">
				<h4 className="card-title no-mb icon">
					<BiArrowBack
						size={40}
						onClick={() => this.props.history.push(`/rigs/edit/${rig.$key}`)}
					/>
					Edit Rig #{rig.number}
				</h4>
				<div className="card-body">
					<Form layout="vertical" onSubmit={(e) => this.handleSubmit(e)} className="theme-form">
						<Row>
							<Col md={12} xl={6} span={12}>
								<p className="primaryColor no-mb"><b>NPT Time</b></p>
								<Form.Item label="Hours">
									{getFieldDecorator('NptHours', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.NptHours
									})(
										<Input type="number" onChange={(e) => this.onInputChange('NptHours', e.target.value)} />
									)}
								</Form.Item>
								{rig.NptHours >= 24 &&
									<label>Hours should be less than 24</label>
								}
							</Col>
							<Col md={12} xl={6} span={12}>
								<p className="no-mb">&nbsp;</p>
								<Form.Item label="Days">
									{getFieldDecorator('NptDays', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.NptDays
									})(
										<Input type="number" onChange={(e) => this.onInputChange('NptDays', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={12} xl={6} span={12}>
								<p className="primaryColor no-mb"><b>Move Time w/o NPT</b></p>
								<Form.Item label="Hours">
									{getFieldDecorator('WoNptHours', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.WoNptHours
									})(
										<Input type="number" disabled={true} onChange={(e) => this.onInputChange('WoNptHours', e.target.value)} />
									)}
								</Form.Item>
							</Col>
							<Col md={12} xl={6} span={12}>
								<p className="no-mb">&nbsp;</p>
								<Form.Item label="Days">
									{getFieldDecorator('WoNptDays', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.WoNptDays
									})(
										<Input type="number" disabled={true} onChange={(e) => this.onInputChange('WoNptDays', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Bump the plug">
									{getFieldDecorator('startRigClock', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.startRigClock && rig.startRigClock !== '' ? moment(rig.startRigClock) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											showTime
											format="DD/MMM/YY HH:mm:ss"
											onChange={(e) => this.onChange('startRigClock', e)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								{/* <Form.Item label="Rig release date">
									{getFieldDecorator('stopRigClock', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.stopRigClock && rig.stopRigClock !== '' ? moment(rig.stopRigClock) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											showTime
											format="DD/MMM/YY HH:mm:ss"
											onChange={(e) => this.onChange('stopRigClock', e)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item> */}
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Subs lowered (optional)">
									{getFieldDecorator('subsLowered', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.subsLowered && rig.subsLowered !== '' ? moment(rig.subsLowered) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											showTime
											format="DD/MMM/YY HH:mm:ss"
											onChange={(e) => this.onChange('subsLowered', e)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Mast lowered">
									{getFieldDecorator('mastLowered', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.mastLowered && rig.mastLowered !== '' ? moment(rig.mastLowered) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											showTime
											format="DD/MMM/YY HH:mm:ss"
											onChange={(e) => this.onChange('mastLowered', e)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Mast raised">
									{getFieldDecorator('mastRaised', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.mastRaised && rig.mastRaised !== '' ? moment(rig.mastRaised) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											showTime
											format="DD/MMM/YY HH:mm:ss"
											onChange={(e) => this.onChange('mastRaised', e)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Subs Raised (optional)">
									{getFieldDecorator('subsRaised', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.subsRaised && rig.subsRaised !== '' ? moment(rig.subsRaised) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											showTime
											format="DD/MMM/YY HH:mm:ss"
											onChange={(e) => this.onChange('subsRaised', e)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col>
							{/* <Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="First Pick">
									{getFieldDecorator('firstPick', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.firstPick && rig.firstPick !== '' ? moment(rig.firstPick) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											showTime
											format="DD/MMM/YY HH:mm:ss"
											onChange={(e) => this.onChange('firstPick', e)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Last Pick">
									{getFieldDecorator('lastPick', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.lastPick && rig.lastPick !== '' ? moment(rig.lastPick) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											showTime
											format="DD/MMM/YY HH:mm:ss"
											onChange={(e) => this.onChange('lastPick', e)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col> */}
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Nipple up BOPE">
									{getFieldDecorator('nippleUpBope', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.nippleUpBope && rig.nippleUpBope !== '' ? moment(rig.nippleUpBope) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											showTime
											format="DD/MMM/YY HH:mm:ss"
											onChange={(e) => this.onChange('nippleUpBope', e)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Begin BOPE testing">
									{getFieldDecorator('beginBope', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.beginBope && rig.beginBope !== '' ? moment(rig.beginBope) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											showTime
											format="DD/MMM/YY HH:mm:ss"
											onChange={(e) => this.onChange('beginBope', e)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Finish BOPE testing">
									{getFieldDecorator('finishBope', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.finishBope && rig.finishBope !== '' ? moment(rig.finishBope) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											showTime
											format="DD/MMM/YY HH:mm:ss"
											onChange={(e) => this.onChange('finishBope', e)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Pickup BHA">
									{getFieldDecorator('pickupBha', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.pickupBha && rig.pickupBha !== '' ? moment(rig.pickupBha) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											showTime
											format="DD/MMM/YY HH:mm:ss"
											onChange={(e) => this.onChange('pickupBha', e)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col>
							{/* <Col md={12} xl={6} span={12}>
								<p className="primaryColor no-mb" style={{ whiteSpace: 'nowrap' }}><b>Total Bope Time</b></p>
								<Form.Item label="Hours">
									{getFieldDecorator('totalBopeHours', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.totalBopeHours,
									})(
										<Input type="number" disabled={true} onChange={(e) => this.onInputChange('totalBopeHours', e.target.value)} />
									)}
								</Form.Item>
							</Col>
							<Col md={12} xl={6} span={12}>
								<p className="no-mb">&nbsp;</p>
								<Form.Item label="Days">
									{getFieldDecorator('totalBopeDays', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.totalBopeDays
									})(
										<Input type="number" disabled={true} onChange={(e) => this.onInputChange('totalBopeDays', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={12} xl={6} span={12}>
								<p className="primaryColor no-mb" style={{ whiteSpace: 'nowrap' }}><b>Release to pickup BHA</b></p>
								<Form.Item label="Hours">
									{getFieldDecorator('releasePickupHours', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.releasePickupHours,
									})(
										<Input type="number" disabled={true} onChange={(e) => this.onInputChange('releasePickupHours', e.target.value)} />
									)}
								</Form.Item>
							</Col>
							<Col md={12} xl={6} span={12}>
								<p className="no-mb">&nbsp;</p>
								<Form.Item label="Days">
									{getFieldDecorator('releasePickupDays', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.releasePickupDays
									})(
										<Input type="number" disabled={true} onChange={(e) => this.onInputChange('releasePickupDays', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={12} xl={6} span={12}>
								<p className="primaryColor no-mb" style={{ whiteSpace: 'nowrap' }}><b>Mast up to pickup BHA</b></p>
								<Form.Item label="Hours">
									{getFieldDecorator('mastPickupHours', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.mastPickupHours,
									})(
										<Input type="number" disabled={true} onChange={(e) => this.onInputChange('mastPickupHours', e.target.value)} />
									)}
								</Form.Item>
							</Col>
							<Col md={12} xl={6} span={12}>
								<p className="no-mb">&nbsp;</p>
								<Form.Item label="Days">
									{getFieldDecorator('mastPickupDays', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.mastPickupDays
									})(
										<Input type="number" disabled={true} onChange={(e) => this.onInputChange('mastPickupDays', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={12} xl={6} span={12}>
								<p className="primaryColor no-mb" style={{ whiteSpace: 'nowrap' }}><b>Plug bump to pickup BHA</b></p>
								<Form.Item label="Hours">
									{getFieldDecorator('plugBumpHours', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.plugBumpHours,
									})(
										<Input type="number" disabled={true} onChange={(e) => this.onInputChange('plugBumpHours', e.target.value)} />
									)}
								</Form.Item>
							</Col>
							<Col md={12} xl={6} span={12}>
								<p className="no-mb">&nbsp;</p>
								<Form.Item label="Days">
									{getFieldDecorator('plugBumpDays', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.plugBumpDays
									})(
										<Input type="number" disabled={true} onChange={(e) => this.onInputChange('plugBumpDays', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Spud-In">
									{getFieldDecorator('spud', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.spud && rig.spud !== '' ? moment(rig.spud) : null
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											showTime
											format="DD/MMM/YY HH:mm:ss"
											onChange={(e) => this.onChange('spud', e)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col> */}
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Rig AFE#">
									{getFieldDecorator('afeNumber', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: rig.afeNumber
									})(
										<Input onChange={(e) => this.onInputChange('afeNumber', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
						</Row>
						<Form.Item className="button-section">
							<Button type="primary" htmlType="submit" size="large" className="submitBtn">
								Save & Finish
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer,
	rig: state.rigReducer
});

export default connect(mapStateToProps)(withRouter(Form.create()(EditRigOptional)));