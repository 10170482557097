export const PARTS_LIST = 'PARTS_LIST';
export const TASKS_LIST = 'TASKS_LIST';
export const RIGS_LIST = 'RIGS_LIST';
export const RESET_RIG_DATA = 'RESET_RIG_DATA';

export const partsList = (data) => ({
	type: PARTS_LIST,
	data
});

export const tasksList = (data) => ({
	type: TASKS_LIST,
	data
});

export const rigsList = (data) => ({
	type: RIGS_LIST,
	data
});

export const resetRigData = () => ({
	type: RESET_RIG_DATA
});