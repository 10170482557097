import {
	PARTS_LIST,
	TASKS_LIST,
	RIGS_LIST,
	RESET_RIG_DATA
} from '../actions/rigActions';


let initialState = {
	partsList: [],
	tasksList: [],
	rigsList: [],
};

const rigReducer = (state = initialState, action) => {
	switch (action.type) {
		case PARTS_LIST:
			return { ...state, partsList: action.data };
		case TASKS_LIST:
			return { ...state, tasksList: action.data };
		case RIGS_LIST:
			return { ...state, rigsList: action.data };
		case RESET_RIG_DATA:
			return { ...initialState };
		default:
			return state;
	}
};

export default rigReducer;
