import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../../../services/firebase';
import '../../report.css';
import Moment from 'react-moment';
import { Row, Col } from 'antd';
// import { FaAngleLeft } from 'react-icons/fa';
// import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import * as moment from 'moment';
import rec from '../../../../assets/imgs/rec.png';
import loader from '../../../../assets/imgs/loader.gif';
import lightning_logo from '../../../../assets/imgs/lightning_logo.jpeg';
import { checkWritePermissions } from '../../../../utils/native';

class LOSReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'reports'),
			id: this.props.match.params.id,
			// i: this.props.match.params.i,
			rig: '',
			report: '',
			rigpics: [],
			selectedDate: '',
			data: [],
			notes: [],
			dataNotes: [],
			personnels: [],
			assets: [],
			dataAssets: [],
			companies: [],
			load: false,
			user: props.auth.currentUser,
			notifications: [],
			elaspsedTime: '',
			date: { days: 0, hour: 0, minutes: 0 },
			up_loadcount_notes: [],
			down_loadcount_notes: [],
			loadcount_notes: [],
			index: [],
			summaries: [],
			rigdown_notes: [],
			rigup_notes: [],
			dates: [],
			rigdown_dates: [],
			rigup_dates: [],
			drilling_summary: [],
			drilling_summary_dates: [],
			cannedSummary: [],
			cannedDates: [],
			cannedUpSummary: [],
			cannedUpDates: [],
			pusherNotes: [],
			pusherDates: [],
			loadPickup: [],
			loadPickupDates: [],
			loadDelivery: [],
			loadDeliveryDates: [],
			equiList: [],
			equiDates: [],
			mastLowered: { days: 0, hours: 0, minutes: 0 },
			mastRaised: { days: 0, hours: 0, minutes: 0 },
			subsLowered: { days: 0, hours: 0, minutes: 0 },
			subsRaised: { days: 0, hours: 0, minutes: 0 },
			nippleUpBope: { days: 0, hours: 0, minutes: 0 },
			beginBope: { days: 0, hours: 0, minutes: 0 },
			finishBope: { days: 0, hours: 0, minutes: 0 },
			pickupBha: { days: 0, hours: 0, minutes: 0 },
			spud: { days: 0, hours: 0, minutes: 0 }
		};
	}

	onLoad = () => {
		this.setState({
			load: true
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'reports') });
		}
	}

	componentDidMount() {
		const db = firebase.firestore();
		const rigs = db.collection('rigReports').where('$key', '==', this.state.id);
		rigs.get().then(async (querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists && doc.data().archive !== true) {
					temp.push(doc.data());
				}
			});
			this.setState({
				report: temp[0],
				selectedDate: temp.date
			});
			const reportOptions = temp[0].reportOptions;
			this.getRigDetails();
			if (temp[0].reportOptions.all === true) {
				this.getDrillingSummaryDownNotes();
				this.getDrillingSummaryUpNotes();
				this.getPusherNotes();
				this.getCompanies();
				this.getRigUpLoadCount();
				this.getRigDownLoadCount();
				this.getRigDownNotes();
				this.getRigUPNotes();
				this.getLoadPickupSignatures();
				this.getRigPics();
				this.getAssets();
				this.getEquipmentInventory();
			} else {
				if (reportOptions['drillingSummary'] && reportOptions.drillingSummary === true) {
					await this.getDrillingSummaryDownNotes();
					await this.getDrillingSummaryUpNotes();
				}
				if (reportOptions['drillingSummaryDown'] && reportOptions.drillingSummaryDown === true) {
					await this.getDrillingSummaryDownNotes();
				}
				if (reportOptions['drillingSummaryUp'] && reportOptions.drillingSummaryUp === true) {
					await this.getDrillingSummaryUpNotes();
				}
				if (reportOptions['pusher'] && reportOptions.pusher === true) {
					await this.getPusherNotes();
				}
				if (reportOptions['company'] && reportOptions.company === true) {
					await this.getCompanies();

				}
				if (reportOptions['tasks'] && reportOptions.tasks === true) {
					await this.getRigUpLoadCount();
					await this.getRigDownLoadCount();
					await this.getRigDownNotes();
					await this.getRigUPNotes();
				}
				if (reportOptions['pics'] && reportOptions.pics === true) {
					await this.getRigPics();
				}
				if (reportOptions['assets'] && reportOptions.assets === true) {
					await this.getAssets();
				}
				if (reportOptions['equipmentInventory'] && reportOptions.equipmentInventory === true) {
					await this.getEquipmentInventory();
				}
				if (reportOptions['loadSignatures'] && reportOptions.loadSignatures === true) {
					await this.getLoadPickupSignatures();
				}
			}
			this.getCurrentUser();
		});
	}

	daysDifference() {
		const { rig } = this.state;
		if (rig.startClock === 'bumpPlug') {
			if (rig.mastLowered !== null) {
				this.setState({ mastLowered: this.elapsedTime(rig.mastLowered, rig.bumpPlug) });
			}
			if (rig.mastRaised !== null) {
				this.setState({ mastRaised: this.elapsedTime(rig.mastRaised, rig.bumpPlug) });
			}
			if (rig.subsLowered !== null) {
				this.setState({ subsLowered: this.elapsedTime(rig.subsLowered, rig.bumpPlug) });
			}
			if (rig.subsRaised !== null) {
				this.setState({ subsRaised: this.elapsedTime(rig.subsRaised, rig.bumpPlug) });
			}
			if (rig.nippleUpBope !== null) {
				this.setState({ nippleUpBope: this.elapsedTime(rig.nippleUpBope, rig.bumpPlug) });
			}
			if (rig.beginBope !== null) {
				this.setState({ beginBope: this.elapsedTime(rig.beginBope, rig.bumpPlug) });
			}
			if (rig.finishBope !== null) {
				this.setState({ finishBope: this.elapsedTime(rig.finishBope, rig.bumpPlug) });
			}
			if (rig.pickupBha !== null) {
				this.setState({ pickupBha: this.elapsedTime(rig.pickupBha, rig.bumpPlug) });
			}
			if (rig.spud !== null) {
				this.setState({ spud: this.elapsedTime(rig.spud, rig.bumpPlug) });
			}
		} else {
			if (rig.mastLowered !== null) {
				this.setState({ mastLowered: this.elapsedTime(rig.mastLowered, rig.releaseDate) });
			}
			if (rig.mastRaised !== null) {
				this.setState({ mastRaised: this.elapsedTime(rig.mastRaised, rig.releaseDate) });
			}
			if (rig.subsLowered !== null) {
				this.setState({ subsLowered: this.elapsedTime(rig.subsLowered, rig.releaseDate) });
			}
			if (rig.subsRaised !== null) {
				this.setState({ subsRaised: this.elapsedTime(rig.subsRaised, rig.releaseDate) });
			}
			if (rig.nippleUpBope !== null) {
				this.setState({ nippleUpBope: this.elapsedTime(rig.nippleUpBope, rig.releaseDate) });
			}
			if (rig.beginBope !== null) {
				this.setState({ beginBope: this.elapsedTime(rig.beginBope, rig.releaseDate) });
			}
			if (rig.finishBope !== null) {
				this.setState({ finishBope: this.elapsedTime(rig.finishBope, rig.releaseDate) });
			}
			if (rig.pickupBha !== null) {
				this.setState({ pickupBha: this.elapsedTime(rig.pickupBha, rig.releaseDate) });
			}
			if (rig.spud !== null) {
				this.setState({ spud: this.elapsedTime(rig.spud, rig.releaseDate) });
			}
		}
	}
	/* ---------------------------- Elapsed Time ---------------------------- */
	elapsedTime(t1, t2) {
		const time1 = moment(t1);
		const time2 = moment(t2);
		const days = time2.diff(time1, 'days');
		let hours;
		if (days > 0)
			hours = time2.diff(time1, 'hours') - (days * 24);
		else
			hours = time2.diff(time1, 'hours');
		const minutes = +moment.utc(moment(time2, 'HH:mm:ss').diff(moment(time1, 'HH:mm:ss'))).format('mm');
		return {
			days: days,
			hours: hours,
			minutes: minutes
		};
	}

	getRigPics() {
		const { report, data } = this.state;
		const selectedDate = moment(report.date).format('YYYY-MM-DD');
		

		const db = firebase.firestore();
		if (!report.endDate) {
			const picsRef = db.collection('rigpics').where('rigId', '==', report.rigId).where('date', '==', selectedDate).orderBy('createdAt', 'desc');
			picsRef.get().then((querySnapshot) => {
				const res = [];
				let temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						temp.$key = doc.id;
						temp = doc.data();
						if (temp.archive !== true) {
							res.push(temp);
							data.push(doc.data().date);
						}
					}
				});
				this.setState({
					rigpics: res,
					data: data.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
			});
		} else {
			const picsRef = db.collection('rigpics').where('rigId', '==', report.rigId).where('date', '>=', selectedDate).where('date', '<=', report.endDate);
			picsRef.get().then((querySnapshot) => {
				const res = [];
				let temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						temp.$key = doc.id;
						temp = doc.data();
						if (temp.archive !== true) {
							res.push(temp);
							data.push(doc.data().date);
						}
					}
				});
				data.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);
				this.setState({
					rigpics: res,
					data: data.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
			});
		}
	}

	/* ---------------------------- Load Signatures - Pickup ---------------------------- */
	getLoadPickupSignatures() {
		const { report, loadPickupDates, loadDeliveryDates } = this.state;
		const selectedDate = moment(report.date).format('YYYY-MM-DD');

		const db = firebase.firestore();
		if (!report.endDate) {
			const picsRef = db.collection('loads').where('rigId', '==', report.rigId).where('date', '==', selectedDate).orderBy('createdAt', 'desc');
			picsRef.get().then((querySnapshot) => {
				const res = [], temp1 = [];
				let temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						temp = doc.data();
						temp.$key = doc.id;
						if (temp.archive !== true) {
							if (temp.type === 'pickup') {
								res.push(temp);
								loadPickupDates.push(doc.data().date);
							}
							if (temp.type === 'delivery') {
								temp1.push(temp);
								loadDeliveryDates.push(doc.data().date);
							}
						}
					}
				});
				this.setState({
					loadPickup: res,
					loadDelivery: temp1,
					loadPickupDates: loadPickupDates.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					}),
					loadDeliveryDates: loadDeliveryDates.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
			});
		} else {
			const picsRef = db.collection('loads').where('rigId', '==', report.rigId).where('date', '>=', selectedDate).where('date', '<=', report.endDate);
			picsRef.get().then((querySnapshot) => {
				const res = [], temp1 = [];
				let temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						temp = doc.data();
						temp.$key = doc.id;
						if (temp.archive !== true) {
							if (temp.type === 'pickup') {
								res.push(temp);
								loadPickupDates.push(doc.data().date);
							}
							if (temp.type === 'delivery') {
								temp1.push(temp);
								loadDeliveryDates.push(doc.data().date);
							}
						}
					}
				});
				loadPickupDates.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);
				loadDeliveryDates.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);
				this.setState({
					loadPickup: res,
					loadDelivery: temp1,
					loadPickupDates: loadPickupDates.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					}),
					loadDeliveryDates: loadDeliveryDates.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
			});
		}
	}

	getAssets() {
		const { report, dataAssets } = this.state;
		const selectedDate = moment(report.date).format('YYYY-MM-DD');
		

		const db = firebase.firestore();
		if (!report.endDate) {
			const picsRef = db.collection('assets').where('rigId', '==', report.rigId).where('date', '==', selectedDate).orderBy('createdAt', 'desc');
			picsRef.get().then((querySnapshot) => {
				const res = [];
				let temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						temp.$key = doc.id;
						temp = doc.data();
						if (temp.archive !== true) {
							res.push(temp);
							dataAssets.push(doc.data().date);
						}
					}
				});
				this.setState({
					assets: res,
					dataAssets: dataAssets.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
			});
		} else {
			const picsRef = db.collection('assets').where('rigId', '==', report.rigId).where('date', '>=', selectedDate).where('date', '<=', report.endDate);
			picsRef.get().then((querySnapshot) => {
				const res = [];
				let temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						temp.$key = doc.id;
						temp = doc.data();
						if (temp.archive !== true) {
							res.push(temp);
							dataAssets.push(doc.data().date);
						}
					}
				});
				dataAssets.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);
				this.setState({
					assets: res,
					dataAssets: dataAssets.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
			});
		}
	}

	getEquipmentInventory() {
		const { report, equiDates } = this.state;
		const selectedDate = moment(report.date).format('YYYY-MM-DD');

		const db = firebase.firestore();
		if (!report.endDate) {
			const picsRef = db.collection('equipments').where('rigId', '==', report.rigId).where('date', '==', selectedDate).orderBy('createdAt', 'desc');
			picsRef.get().then((querySnapshot) => {
				const res = [];
				let temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						temp.$key = doc.id;
						temp = doc.data();
						if (temp.archive !== true) {
							res.push(temp);
							equiDates.push(doc.data().date);
						}
					}
				});
				this.setState({
					equiList: res,
					equiDates: equiDates.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
			}).catch(() => {
			});
		} else {
			const picsRef = db.collection('equipments').where('rigId', '==', report.rigId).where('date', '>=', selectedDate).where('date', '<=', report.endDate);
			picsRef.get().then((querySnapshot) => {
				const res = [];
				let temp = [];
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						temp.$key = doc.id;
						temp = doc.data();
						if (temp.archive !== true) {
							res.push(temp);
							equiDates.push(doc.data().date);
						}
					}
				});
				equiDates.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);
				this.setState({
					equiList: res,
					equiDates: equiDates.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
			}).catch(() => {
			});
		}
	}

	getRigDetails() {
		const { report } = this.state;
		if (report.rigId) {
			const { rigsList } = this.props.rig;
			const temp = rigsList.find((item) => item.$key === report.rigId);
			this.setState({ rig: temp }, () => {
				this.daysDifference();
				this.rigmoveClockCount();
			});
		}
	}

	/* ---------------------------- Drilling Summary Notes ---------------------------- */
	/* ---------------------------- RigDown & Other ---------------------------- */
	getDrillingSummaryDownNotes() {
		const { report, cannedDates, drilling_summary_dates } = this.state;
		const selectedDate = moment(report.date).format('YYYY-MM-DD');
		

		const database = firebase.firestore();
		if (!report.endDate) {
			const rigs = database.collection('drilling-rig').where('rigId', '==', report.rigId).where('type', '==', 'Summary').where('date', '==', selectedDate).orderBy('time', 'desc');
			rigs.get().then((querySnapshot) => {
				const temp = [], temp1 = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						if (data.selectType === 'Rig Down') {
							temp.push(data);
						}
						if (data.selectType === undefined && (report.reportOptions.all === true || report.reportOptions.drillingSummary === true)) {
							temp1.push(data);
						}
					}
				});
				this.setState({
					cannedSummary: temp,
					drilling_summary: temp1
				});
			});
		} else {
			const rigs = database.collection('drilling-rig').where('rigId', '==', report.rigId).where('type', '==', 'Summary').where('date', '>=', selectedDate).where('date', '<=', report.endDate);
			rigs.get().then((querySnapshot) => {
				const temp = [], temp1 = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						if (data.selectType === 'Rig Down') {
							temp.push(data);
							cannedDates.push(data.date);
						}
						if (data.selectType === undefined && (report.reportOptions.all === true || report.reportOptions.drillingSummary === true)) {
							temp1.push(data);
							drilling_summary_dates.push(data.date);
						}
					}
				});
				cannedDates.sort((a, b) => (a.time > b.time) ? 1 : -1);
				drilling_summary_dates.sort((a, b) => (a.time > b.time) ? 1 : -1);
				this.setState({
					cannedSummary: temp,
					drilling_summary: temp1,
					cannedDates: cannedDates.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					}),
					drilling_summary_dates: drilling_summary_dates.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
			});
		}
	}

	/* ---------------------------- RigUp ---------------------------- */
	getDrillingSummaryUpNotes() {
		const { report, cannedUpDates } = this.state;
		const selectedDate = moment(report.date).format('YYYY-MM-DD');
		

		const database = firebase.firestore();
		if (!report.endDate) {
			const rigs = database.collection('drilling-rig').where('rigId', '==', report.rigId).where('type', '==', 'Summary').where('date', '==', selectedDate).orderBy('time', 'desc');
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						if (data.selectType === 'Rig Up') {
							temp.push(data);
						}
					}
				});
				this.setState({
					cannedUpSummary: temp
				});
			});
		} else {
			const rigs = database.collection('drilling-rig').where('rigId', '==', report.rigId).where('type', '==', 'Summary').where('date', '>=', selectedDate).where('date', '<=', report.endDate);
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						if (data.selectType === 'Rig Up') {
							temp.push(data);
							cannedUpDates.push(data.date);
						}
					}
				});
				cannedUpDates.sort((a, b) => (a.time > b.time) ? 1 : -1);
				this.setState({
					cannedUpSummary: temp,
					cannedUpDates: cannedUpDates.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
			});
		}
	}

	/* ---------------------------- Pusher Notes ---------------------------- */
	getPusherNotes() {
		const { report, pusherDates } = this.state;
		const selectedDate = moment(report.date).format('YYYY-MM-DD');
		

		const database = firebase.firestore();
		if (!report.endDate) {
			const rigs = database.collection('pusher').where('rigId', '==', report.rigId).where('date', '==', selectedDate).orderBy('time', 'desc');
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						temp.push(data);
					}
				});
				this.setState({
					pusherNotes: temp
				});
			});
		} else {
			const rigs = database.collection('pusher').where('rigId', '==', report.rigId).where('date', '>=', selectedDate).where('date', '<=', report.endDate);
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						temp.push(data);
						pusherDates.push(data.date);
					}
				});
				pusherDates.sort((a, b) => (a.time > b.time) ? 1 : -1);
				this.setState({
					pusherNotes: temp,
					pusherDates: pusherDates.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
			});
		}
	}

	/* ---------------------------- Companies ---------------------------- */
	getCompanies() {
		const { report } = this.state;
		const selectedDate = moment(report.date).format('YYYY-MM-DD');
		

		const database = firebase.firestore();
		if (!report.endDate) {
			const rigs = database.collection('rigCompanies').where('rigId', '==', report.rigId).where('date', '==', selectedDate).orderBy('createdAt', 'desc');
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						temp.push(data);
					}
				});
				this.setState({
					companies: temp
				});
			});
		} else {
			const rigs = database.collection('rigCompanies').where('rigId', '==', report.rigId).where('date', '>=', selectedDate).where('date', '<=', report.endDate);
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						temp.push(data);
					}
				});
				temp.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);
				this.setState({
					companies: temp
				});
			});
		}
	}

	/* ---------------------------- Rigmove Clock ---------------------------- */
	rigmoveClockCount() {
		const { rig } = this.state;
		if ((rig.startClock && !rig.endClock) || (rig.startClock && rig.endClock === '')) {
			if (rig.startClock === 'startRigClock' && (rig.startRigClock === undefined || rig.startRigClock === '')) {
				this.noDateDiff();
			} else {
				const startDate = rig.startRigClock && moment(rig.startRigClock.replace('Z', ''));
				const currentDate = moment();
				this.calculateDateDiff(moment(startDate), currentDate, false);
			}
		}
		if (rig.startClock === 'startRigClock' && rig.endClock === 'stopRigClock') {
			if (rig.startRigClock && rig.stopRigClock && rig.startRigClock !== null && rig.stopRigClock !== '') {
				this.calculateDateDiff(rig.startRigClock, rig.stopRigClock, true);
			} else if (rig.stopRigClock === '') {
				const startDate = moment(rig.startRigClock);
				const currentDate = moment();
				this.calculateDateDiff(startDate, currentDate, true);
			} else {
				this.noDateDiff();
			}
		}
	}

	calculateDateDiff(date1, date2, utc) {
		const { date } = this.state;
		if (utc === true) {
			const release = moment(date1);
			const pickup = moment(date2);
			date.days = pickup.diff(release, 'days');
			date.hour = pickup.diff(release, 'hours') - (date.days * 24);
			date.minutes = +moment.utc(moment(pickup, 'HH:mm:ss').diff(moment(release, 'HH:mm:ss'))).format('mm');
			this.setState({ date });
		} else {
			const currentDate = moment(date2, 'MM-DD-YYYY HH:mm:ss').valueOf();
			const startDate = moment(date1, 'MM-DD-YYYY HH:mm:ss').valueOf();
			var temp = Math.abs(currentDate - startDate) / 1000;
			date.days = Math.floor(temp / 86400);
			date.hour = Math.floor(temp / 3600) % 24;
			date.minutes = Math.floor(temp / 60) % 60;
			this.setState({ date });
		}
	}

	noDateDiff() {
		const { date } = this.state;
		date.days = 0;
		date.hour = 0;
		date.minutes = 0;
		this.setState({ date });
	}

	/* ---------------------------- LoadCount ---------------------------- */
	getRigUpLoadCount() {
		const { report } = this.state;
		
		const database = firebase.firestore();
		const rigs = database.collection('Rig-Up').where('rigId', '==', report.rigId).orderBy('time', 'desc');
		rigs.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				const data = doc.data();
				data.$key = doc.id;
				if (doc.exists && data.archive !== true) {
					if (data.loadcount === true) {
						temp.push(data);
					}
				}
			});
			this.setState({
				up_loadcount_notes: temp
			});
		});
	}

	getRigDownLoadCount() {
		const { report } = this.state;
		const database = firebase.firestore();
		const rigs = database.collection('Rig-Down').where('rigId', '==', report.rigId);
		rigs.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				const data = doc.data();
				data.$key = doc.id;
				if (doc.exists && data.archive !== true) {
					if (data.loadcount === true) {
						temp.push(data);
					}
				}
			});
			this.setState({
				down_loadcount_notes: temp
			});
			this.totalLoadCount();
		});
	}

	totalLoadCount() {
		const { notes, up_loadcount_notes, down_loadcount_notes, loadcount_notes } = this.state;
		const total_array = up_loadcount_notes.concat(down_loadcount_notes);
		this.setState({
			loadcount_notes: total_array
		});

		loadcount_notes.forEach(note => {
			const dateString = moment(note.date).format('YYYY-MM-DD');
			const index = this.state.index.indexOf(note.date);
			if (index === -1) {
				index.push(note.date);
				const newIndex = index.indexOf(note.date);
				notes[newIndex] = {
					title: dateString,
					count: 0
				};
				notes[newIndex].count++;
			}
			else {
				if (!notes[index]) {
					notes[index] = {
						title: dateString,
						count: 0
					};
				}
				notes[index].count++;
			}
		});

		notes.sort((a, b) => (a.title > b.title) ? -1 : 1);
		this.setState({ notes });
	}

	/* ---------------------------- RigDown Notes ---------------------------- */
	getRigDownNotes() {
		const { report, rigdown_dates } = this.state;
		const selectedDate = moment(report.date).format('YYYY-MM-DD');
		

		const database = firebase.firestore();
		if (!report.endDate) {
			const rigs = database.collection('Rig-Down').where('rigId', '==', report.rigId).where('date', '==', selectedDate).orderBy('time', 'desc');
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						temp.push(data);
					}
				});
				this.setState({
					rigdown_notes: temp
				});
			});
		} else {
			const rigs = database.collection('Rig-Down').where('rigId', '==', report.rigId).where('date', '>=', selectedDate).where('date', '<=', report.endDate);
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						temp.push(data);
						rigdown_dates.push(data.date);
					}
				});
				temp.sort((a, b) => (a.time < b.time) ? 1 : -1);
				rigdown_dates.sort((a, b) => (a.time > b.time) ? 1 : -1);
				this.setState({
					rigdown_notes: temp,
					rigdown_dates: rigdown_dates.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
			});
		}
	}

	/* ---------------------------- RigUp Notes ---------------------------- */
	getRigUPNotes() {
		const { report, rigup_dates } = this.state;
		const selectedDate = moment(report.date).format('YYYY-MM-DD');

		const database = firebase.firestore();
		if (!report.endDate) {
			const rigs = database.collection('Rig-Up').where('rigId', '==', report.rigId).where('date', '==', selectedDate).orderBy('time', 'desc');
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						temp.push(data);
					}
				});
				this.setState({
					rigup_notes: temp
				});
			});
		} else {
			const rigs = database.collection('Rig-Up').where('rigId', '==', report.rigId).where('date', '>=', selectedDate).where('date', '<=', report.endDate);
			rigs.get().then((querySnapshot) => {
				const temp = [];
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					data.$key = doc.id;
					if (doc.exists && data.archive !== true) {
						temp.push(data);
						rigup_dates.push(data.date);
					}
				});
				temp.sort((a, b) => (a.time < b.time) ? 1 : -1);
				rigup_dates.sort((a, b) => (a.time > b.time) ? 1 : -1);
				this.setState({
					rigup_notes: temp,
					rigup_dates: rigup_dates.filter((item, index, inputArray) => {
						return inputArray.indexOf(item) === index;
					})
				});
			});
		}
	}

	getCurrentUser() {
		const { report } = this.state;

		if (report.uid && this.props.auth.currentUser.$key !== report.uid) {
			const { usersList } = this.props.auth;
			const temp = usersList.find((item) => item.uid === report.uid);
			this.setState({ user: temp });
		}
	}

	// View rendered functions
	renderTaskMilestones() {
		const { report, cannedSummary, cannedDates, cannedUpSummary, cannedUpDates, drilling_summary, drilling_summary_dates } = this.state;
		return (
			(cannedSummary.length !== 0 || cannedUpSummary.length !== 0 || drilling_summary.length !== 0) &&
			<Col md={24} xl={24} span={24}>
				{/* <!-- ---------------------------- RigDown ---------------------------- --> */}
				{/* <!-- ---------------------------- without endDate ---------------------------- --> */}
				<h3 className='text-center card-title'>Task/Notes Milestones</h3>
				{
					!report.endDate && cannedSummary.length !== 0 &&
					<div className='reportDetails'>
						<h6 className='card-title'>RigDown</h6>
						<p><Moment format='DD/MM/YYYY'>{report.date}</Moment></p>
						{cannedSummary.map((summary, index) => {
							return (
								<div className='small-details-box' key={index.toString()}>
									<span><Moment format='h:mm a'>{summary.time}</Moment></span>
									<p><b>Description: </b>{summary.description}</p>
									{summary.cannedMsg &&
										<b>Canned Messages:</b>
									}
									{summary.cannedMsg && summary.cannedMsg.map((canned) => {
										return (
											<span key={canned.toString()} style={{ color: 'red' }}>{canned}, </span>
										);
									})}
								</div>
							);
						})}
					</div>
				}
				{/* <!-- ---------------------------- with endDate ---------------------------- --> */}
				{report.endDate && cannedSummary.length !== 0 &&
					<div className='reportDetails'>
						<h6 className='card-title'>RigDown</h6>
						<div className='small-details-box-row'>
							{cannedDates.map((date, index) => {
								return (
									<div className='small-details-box' key={index.toString()}>
										<p className="date">{date}</p>
										{cannedSummary.map((summary) => {
											return (
												<div className='content' key={summary.$key}>
													<span><Moment format='h:mm a'>{summary.time}</Moment></span>
													<p><b>Description: </b>{summary.description}</p>
													{summary.cannedMsg &&
														<p className="no-mb"><b>Canned Messages:</b></p>
													}
													{summary.cannedMsg && summary.cannedMsg.map((canned) => {
														return (
															<span className='chips-blog' key={canned.toString()}>{canned}</span>
														);
													})}
												</div>
											);
										})}
									</div>
								);
							})}
						</div>
					</div>
				}
				{/* <!-- ---------------------------- RigUp ---------------------------- --> */}
				{/* <!-- ---------------------------- without endDate ---------------------------- --> */}
				{
					!report.endDate && cannedUpSummary.length !== 0 &&
					<div className='reportDetails'>
						<h6 className='card-title'>RigUp</h6>
						<p><Moment format='DD/MM/YYYY'>{report.date}</Moment></p>
						<div className='small-details-box-row'>
							{cannedUpSummary.map((summary, index) => {
								return (
									<div className='small-details-box' key={index.toString()}>
										<span><Moment format='h:mm a'>{summary.time}</Moment></span>
										<p><b>Description: </b>{summary.description}</p>
										{summary.cannedMsg &&
											<p className="no-mb"><b>Canned Messages:</b></p>
										}
										{summary.cannedMsg && summary.cannedMsg.map((canned) => {
											return (
												<span className='chips-blog' key={canned.toString()}>{canned}</span>
											);
										})}
									</div>
								);
							})}
						</div>
					</div>
				}
				{/* <!-- ---------------------------- with endDate ---------------------------- --> */}
				{report.endDate && cannedUpSummary.length !== 0 &&
					<div className='reportDetails'>
						<h6 className='card-title'>RigUp</h6>
						<div className='small-details-box-row'>
							{cannedUpDates.map((date, index) => {
								return (
									<div className='small-details-box' key={index.toString()}>
										<p className="date">{date}</p>
										{cannedUpSummary.map((summary) => {
											return (
												<div className='content' key={summary.$key}>
													<span><Moment format='h:mm a'>{summary.time}</Moment></span>
													<p><b>Description: </b>{summary.description}</p>
													{summary.cannedMsg &&
														<p className="no-mb"><b>Canned Messages:</b></p>
													}
													{summary.cannedMsg && summary.cannedMsg.map((canned) => {
														return (
															<span className='chips-blog' key={canned.toString()}>{canned}</span>
														);
													})}
												</div>
											);
										})}
									</div>
								);
							})}
						</div>
					</div>
				}
				{/* <!-- ---------------------------- Only Summary without type ---------------------------- --> */}
				{/* <!-- ---------------------------- without endDate ---------------------------- --> */}
				{
					!report.endDate && drilling_summary.length !== 0 &&
					(report.reportOptions.all === true || report.reportOptions.drillingSummary === true) &&
					<div className='reportDetails'>
						<hr className="report-line" />
						<h6 className='card-title'>Rig</h6>
						<p><Moment format='DD/MM/YYYY'>{report.date}</Moment></p>
						<div className='small-details-box-row'>
							{drilling_summary.map((summary, index) => {
								return (
									<div className='small-details-box' key={index.toString()}>
										<span><Moment format='h:mm a'>{summary.time}</Moment></span>
										<p><b>Description: </b>{summary.description}</p>
										{summary.cannedMsg &&
											<p><b>Canned Messages:</b></p>
										}
										{summary.cannedMsg && summary.cannedMsg.map((canned) => {
											return (
												<span key={canned.toString()} style={{ color: 'red' }}>{canned}, </span>
											);
										})}
									</div>
								);
							})}
						</div>
					</div>
				}
				{/* <!-- ---------------------------- with endDate ---------------------------- --> */}
				{report.endDate && drilling_summary.length !== 0 &&
					(report.reportOptions.all === true || report.reportOptions.drillingSummary === true) &&
					<div className='reportDetails'>
						<hr className="report-line" />
						<h6 className='card-title'>Rig</h6>
						<div className='small-details-box-row'>
							{drilling_summary_dates.map((date, index) => {
								return (
									<div className='small-details-box' key={index.toString()}>
										<p className="date">{date}</p>
										{drilling_summary.map((summary) => {
											return (
												<div className='content' key={summary.$key}>
													<span><Moment format='h:mm a'>{summary.time}</Moment></span>
													<p><b>Description: </b>{summary.description}</p>
													{summary.cannedMsg &&
														<p><b>Canned Messages:</b></p>
													}
													{summary.cannedMsg && summary.cannedMsg.map((canned) => {
														return (
															<span className='chips-blog' key={canned.toString()}>{canned}</span>
														);
													})}
												</div>
											);
										})}
									</div>
								);
							})}
						</div>
					</div>
				}
			</Col>
		);
	}

	renderPusherNotes() {
		const { report, pusherNotes, pusherDates } = this.state;
		return (
			<Col md={24} xl={24} span={24}>
				<hr className="report-line" />
				{/* <!-- ---------------------------- Pusher Notes ---------------------------- --> */}
				{/* <!-- ---------------------------- without endDate ---------------------------- --> */}
				<h6 className="card-title">Pusher Notes</h6>
				{
					!report.endDate && pusherNotes.length !== 0 &&
					<div className="reportDetails">
						<p className="date"><Moment format='DD/MM/YYYY'>{report.date}</Moment></p>
						<div className='small-details-box-row'>
							{pusherNotes.map((summary, index) => {
								return (
									<div className='small-details-box' key={index.toString()}>
										<span><Moment format='h:mm a'>{summary.time}</Moment></span>
										{!summary.edited &&
											<p><b>Description: </b>{summary.description}</p>
										}
										{summary.edited && summary.entries.map((entry, i) => {
											return (
												<div key={summary.$key + i}>
													{i === 0 &&
														<p><b>Description: </b>{entry.description}</p>
													}
													{i > 0 &&
														<div>
															<p><b><Moment format='h:mm a'>{entry.time}</Moment>: New Entry</b></p>
															<p><b>Description: </b>{entry.description}</p>
														</div>
													}
												</div>
											);
										})}
										{summary.lessons &&
											<>
												<p style={{ color: 'red' }}><b>Lessons Learned:</b></p>
												<span>{summary.lessons}</span>
											</>
										}
										{summary.totalRigDown && <p className="data-texts rig-down">Rig Down: {summary.totalRigDown}</p>}
										{summary.totalRigUp && <p className="data-texts rig-up">Rig Up: {summary.totalRigUp}</p>}
									</div>
								);
							})}
						</div>
					</div>
				}
				{/* <!-- ---------------------------- with endDate ---------------------------- --> */}
				{report.endDate && pusherNotes.length !== 0 &&
					<div className='reportDetails'>
						<div className='small-details-box-row'>
							{pusherDates.map((date, index) => {
								return (
									<div className='small-details-box' key={index.toString()}>
										<p className="date">{date}</p>
										{pusherNotes.map((summary) => {
											return (
												<div className='content' key={summary.$key}>
													{date === summary.date &&
														<>
															<span><Moment format='h:mm a'>{summary.time}</Moment></span>
															{!summary.edited &&
																<p><b>Description: </b>{summary.description}</p>
															}
															{summary.edited && summary.entries.map((entry, i) => {
																return (
																	<div key={summary.$key + i}>
																		{i === 0 &&
																			<p><b>Description: </b>{entry.description}</p>
																		}
																		{i > 0 &&
																			<div>
																				<p><b><Moment format='h:mm a'>{entry.time}</Moment>: New Entry</b></p>
																				<p><b>Description: </b>{entry.description}</p>
																			</div>
																		}
																	</div>
																);
															})}
															{summary.lessons &&
																<>
																	<p className='error-text'>Lessons Learned</p>
																	<span className='chips-blog'>{summary.lessons}</span>
																</>
															}
															{summary.totalRigDown && <p className="data-texts rig-down">Rig Down: {summary.totalRigDown}</p>}
															{summary.totalRigUp && <p className="data-texts rig-up">Rig Up: {summary.totalRigUp}</p>}
														</>
													}
												</div>
											);
										})}
									</div>
								);
							})}
						</div>
					</div>
				}
			</Col>
		);
	}

	renderCompanies() {
		const { companies } = this.state;
		return (
			<Col md={24} xl={24} span={24}>
				<hr className="report-line" />
				{/* --------------------------- Companies --------------------------- */}
				{
					companies.length > 0 &&
					<div className='reportDetails'>
						<div className='company_details'>
							<h3 className='text-center card-title'>Companies & Equipment</h3>
							<div className='company_contains'>
								{companies.map((company) => {
									return (
										<div className='company-reports' key={(company.$key).toString()}>
											<div className="company-reports-box">
												<h6 className='card-title no-mb'>Company Name: {company.name}</h6>
												<p className='company_role'>Role: {company.role}</p>
												{company.equipment &&
													<div>
														<p><b>Equipment</b></p>
														{company.equipment.map((equipment, ind) => {
															return (
																<div key={(company.$key + ind).toString()}>
																	<p>Date: {equipment.date}</p>
																	{equipment.day &&
																		<>
																			<p><b>Day:</b> {equipment.day}</p>
																			<div className='table-company'>
																				<Row className='table-head'>
																					<Col><p><b>Qty</b></p></Col>
																					<Col><p><b>Equipment Type</b></p></Col>
																					<Col><p><b>Day Usage</b></p></Col>
																				</Row>
																				{equipment.name.map((equi, i) => {
																					return (
																						<div className='table-body' key={i.toString()}>
																							{equi.quantity !== '' &&
																								<Row>
																									<Col>{equi.quantity}</Col>
																									<Col>{equi.type}</Col>
																									<Col>{equi.usage}</Col>
																								</Row>
																							}
																						</div>
																					);
																				})}
																			</div>
																		</>
																	}
																	{equipment.notes && <p>Notes: <span>{equipment.notes}</span></p>}
																</div>
															);
														})}
													</div>
												}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				}
			</Col>
		);
	}

	renderRigmoveClock() {
		const { rig, date } = this.state;
		return (
			<Col md={24} xl={24} span={24}>
				<hr className="report-line" />
				<div className='reportDetails'>
					<h6 className='card-title'>Rig Clock</h6>
					<div className='small-details-box-row'>
						<div className='small-details-box'>
							<p>
								{date.days > 0 && <span>{date.days} Days </span>}
								<span>{date.hour} Hrs {date.minutes} Mins</span>
							</p>
							<p>Elapsed since rig release on</p>
							{rig.releaseDate &&
								<p><Moment format='DD/MM/YYYY'>{rig.releaseDate}</Moment>
									<span> <Moment format='HH:mm'>{rig.releaseDate.replace('Z', '')}</Moment></span>
								</p>
							}
						</div>
					</div>
				</div>
			</Col>
		);
	}

	renderLoadCount() {
		const { rig, notes } = this.state;
		return (
			<Col md={24} xl={24} span={24}>
				<hr className="report-line" />
				<div className='reportDetails'>
					<h6 className='card-title'>Total Load Count: {rig.loadcount ? rig.loadcount : 0}</h6>
					<div className='small-details-box-row'>
						{notes.length !== 0 &&
							<div className='small-details-box'>
								<p><b>Daily Load Count</b></p>
								<div>
									{notes.map((key, index) => {
										return (
											<p key={index.toString()}>{key.title} : <span><b>{key.count}</b></span></p>
										);
									})}
								</div>
							</div>
						}
					</div>
				</div>
			</Col>
		);
	}

	renderRigdownNotes() {
		const { report, rigdown_notes, rigdown_dates } = this.state;
		return (
			<Col md={24} xl={24} span={24}>
				<hr className="report-line" />
				<h6 className='card-title'>Rig Down</h6>
				{/* <---------------------------- RigDown without endDate ----------------------------> */}
				{rigdown_notes.length !== 0 && !report.endDate &&
					<>
						<p><Moment format='DD/MM/YYYY'>{report.date}</Moment></p>
						<div className='report-details'>
							{rigdown_notes.map((notes, index) => {
								return (
									<div className='small-details-box displayNotes' key={(notes.$key).toString()}>
										<p><Moment format='HH:mm'>{notes.time}</Moment></p>
										{notes.companyName && <p><b>Company:</b> {notes.companyName}</p>}
										{notes.driverName && <p><b>Driver Name:</b> {notes.driverName}</p>}
										{notes.truck && <p><b>Truck #:</b> {notes.truck}</p>}
										{notes.load_type && <p><b>Load Type (Truck):</b> {notes.load_type}</p>}
										{notes.part && <p><b>Rig Part:</b> {notes.part.map((note, index) => {
											return (
												<span className='chips' key={(notes.$key + index).toString()}>
													{note.name ? <span>{note.name}</span> : <span>{note}</span>}
												</span>
											);
										})}</p>}
										{notes.task && <p key={(notes.$key + index).toString()}><b>Task:</b> {notes.task.map((key, index) => {
											return (
												<span key={(notes.$key + index).toString()}>{key}</span>
											);
										})}</p>}
										{notes.load_type && <p><b>Load Type (Trailer):</b> {notes.load_type}</p>}
										{notes.npt && <p><b>Examine NPT:</b> {notes.npt}</p>}
										{notes && notes.permitLoad === true && notes.dimensions.height && (notes.dimensions.height.feet || notes.dimensions.height.inches) &&
											<p>
												<b>Height: </b>
												{notes.dimensions.height.feet && <span>{notes.dimensions.height.feet} ft </span>}
												{notes.dimensions.height.inches && <span>{notes.dimensions.height.inches} inch</span>}
											</p>
										}
										{notes && notes.permitLoad === true && notes.dimensions.width && (notes.dimensions.width.feet || notes.dimensions.width.inches) &&
											<p>
												<b>Width: </b>
												{notes.dimensions.width.feet && <span>{notes.dimensions.width.feet} ft </span>}
												{notes.dimensions.width.inches && <span>{notes.dimensions.width.inches} inch</span>}
											</p>
										}
										{notes && notes.permitLoad === true && notes.dimensions.length && (notes.dimensions.length.feet || notes.dimensions.length.inches) &&
											<p>
												<b>Length: </b>
												{notes.dimensions.length.feet && <span>{notes.dimensions.length.feet} ft </span>}
												{notes.dimensions.length.inches && <span>{notes.dimensions.length.inches} inch</span>}
											</p>
										}
										{notes && notes.permitLoad === true && notes.dimensions.truck && (notes.dimensions.truck.feet || notes.dimensions.truck.inches) &&
											<p>
												<b>Total Overhang on Trailer: </b>
												{notes.dimensions.truck.feet && <span>{notes.dimensions.truck.feet} ft </span>}
												{notes.dimensions.truck.inches && <span>{notes.dimensions.truck.inches} inch</span>}
											</p>
										}
										{notes.axles && <p><b># of Tractor Axles:</b> {notes.tractorAxles}</p>}
										{notes.axles && <p><b># of Trailer Axles:</b> {notes.axles}</p>}
										{notes.stingerAxles && <p className='data-texts'><b># of Stinger Axles:</b> {notes.stingerAxles}</p>}
										{notes.jeepAxles && <p className='data-texts'><b># of Jeep Axles:</b> {notes.jeepAxles}</p>}
										{notes && notes.arrived_location === true &&
											<>
												<p><b>Arrived on Date: </b><Moment format='DD/MM/YYYY HH:mm a'>{notes.arrived_time}</Moment></p>
												<p><b>Arrived on New Location: </b>
													{notes.arrived_diff.days > 0 && <span>{notes.arrived_diff.days} Days </span>}
													<span>{notes.arrived_diff.hour} Hrs {notes.arrived_diff.minutes} Min</span>
												</p>
											</>
										}
										{notes.timer && notes.timer.selectedTime && <p><b>Time Start:</b> {notes.timer.selectedTime}</p>}
										{notes.timer && notes.timer.stoppedTime && <p><b>Time Stop:</b> {notes.timer.stoppedTime}</p>}
										{!notes.timer.start && notes.timer.stoppedTime && <p><b>Load Time:</b> {notes.timer.time}</p>}
										{notes.timer.start && notes.timer.stoppedTime && <p><b>Clock:</b> <span>ON</span></p>}
										{notes.description && <p><b>Description: </b>{notes.description}</p>}
									</div>
								);
							})}
						</div>
					</>
				}

				{/* <---------------------------- RigDown with endDate ----------------------------> */}
				{rigdown_notes && rigdown_notes.length !== 0 && report.endDate &&
					<div className='reportDetails'>
						{rigdown_dates.map((date, index) => {
							return (
								<div key={index}>
									<p><Moment format='DD/MM/YYYY'>{date}</Moment></p>
									<div className='small-details-box-row'>
										{rigdown_notes.map((notes) => {
											return (
												<div className={notes && date === notes.date ? 'small-details-box displayNotes' : ''} key={(notes.$key).toString()}>
													{notes && date === notes.date &&
														<div>
															<p><b><Moment format='HH:mm'>{notes.time}</Moment></b></p>
															{notes.companyName && <p><b>Company:</b> {notes.companyName}</p>}
															{notes.driverName && <p className='data-texts'><b>Driver Name:</b> {notes.driverName}</p>}
															{notes.truck && <p><b>Truck #:</b> {notes.truck}</p>}
															{notes.load_type && <p><b>Load Type (Truck):</b> {notes.load_type}</p>}
															{notes.part && <p><b>Rig Part:</b> {notes.part.map((note, index) => {
																return (
																	<span className='chips' key={(notes.$key + index).toString()}>
																		{note.name ? <span>{note.name}</span> : <span>{note}</span>}
																	</span>
																);
															})}</p>}
															{notes.task && <p><b>Task:</b> {notes.task.map((key, index) => {
																return (
																	<span key={(notes.$key + index).toString()}>{key}</span>
																);
															})}</p>}
															{notes.load_type && <p className='data-texts'><b>Load Type (Trailer):</b> {notes.load_type}</p>}
															{notes.npt && <p><b>Examine NPT:</b> {notes.npt}</p>}
															{notes && notes.permitLoad === true && notes.dimensions.height && (notes.dimensions.height.feet || notes.dimensions.height.inches) &&
																<p>
																	<b>Height: </b>
																	{notes.dimensions.height.feet && <span>{notes.dimensions.height.feet} ft </span>}
																	{notes.dimensions.height.inches && <span>{notes.dimensions.height.inches} inch</span>}
																</p>
															}
															{notes && notes.permitLoad === true && notes.dimensions.width && (notes.dimensions.width.feet || notes.dimensions.width.inches) &&
																<p>
																	<b>Width: </b>
																	{notes.dimensions.width.feet && <span>{notes.dimensions.width.feet} ft </span>}
																	{notes.dimensions.width.inches && <span>{notes.dimensions.width.inches} inch</span>}
																</p>
															}
															{notes && notes.permitLoad === true && notes.dimensions.length && (notes.dimensions.length.feet || notes.dimensions.length.inches) &&
																<p>
																	<b>Length: </b>
																	{notes.dimensions.length.feet && <span>{notes.dimensions.length.feet} ft </span>}
																	{notes.dimensions.length.inches && <span>{notes.dimensions.length.inches} inch</span>}
																</p>
															}
															{notes && notes.permitLoad === true && notes.dimensions.truck && (notes.dimensions.truck.feet || notes.dimensions.truck.inches) &&
																<p>
																	<b>Total Overhang on Trailer: </b>
																	{notes.dimensions.truck.feet && <span>{notes.dimensions.truck.feet} ft </span>}
																	{notes.dimensions.truck.inches && <span>{notes.dimensions.truck.inches} inch</span>}
																</p>
															}
															{notes.axles && <p className='data-texts'><b># of Tractor Axles:</b> {notes.tractorAxles}</p>}
															{notes.axles && <p className='data-texts'><b># of Trailer Axles:</b> {notes.axles}</p>}
															{notes.stingerAxles && <p className='data-texts'><b># of Stinger Axles:</b> {notes.stingerAxles}</p>}
															{notes.jeepAxles && <p className='data-texts'><b># of Jeep Axles:</b> {notes.jeepAxles}</p>}
															{notes && notes.arrived_location === true &&
																<>
																	<p><b>Arrived on Date: </b><Moment format='DD/MM/YYYY HH:mm a'>{notes.arrived_time}</Moment></p>
																	<p><b>Arrived on New Location: </b>
																		{notes.arrived_diff.days > 0 && <span>{notes.arrived_diff.days} Days </span>}
																		<span>{notes.arrived_diff.hour} Hrs {notes.arrived_diff.minutes} Min</span>
																	</p>
																</>
															}
															{notes.timer && notes.timer.selectedTime && <p><b>Time Start:</b> {notes.timer.selectedTime}</p>}
															{notes.timer && notes.timer.stoppedTime && <p><b>Time Stop:</b> {notes.timer.stoppedTime}</p>}
															{!notes.timer.start && notes.timer.stoppedTime && <p><b>Load Time:</b> {notes.timer.time}</p>}
															{notes.timer.start && notes.timer.stoppedTime && <p><b>Clock:</b> <span>ON</span></p>}
															{notes.description && <p className='data-texts'><b>Description: </b>{notes.description}</p>}
														</div>
													}
												</div>
											);
										})}
									</div>
								</div>
							);
						})}
					</div>
				}
			</Col>
		);
	}

	renderRigUpNotes() {
		const { report, rigup_notes, rigup_dates } = this.state;
		return (
			<Col md={24} xl={24} span={24}>
				<hr className="report-line" />
				<h6 className='card-title'>Rig Up</h6>
				{/* <---------------------------- RigUp without endDate ----------------------------> */}
				{rigup_notes.length !== 0 && !report.endDate &&
					<>
						<p><Moment format='DD/MM/YYYY'>{report.date}</Moment></p>
						<div className='report-details dataTexts'>
							{rigup_notes.map((notes) => {
								return (
									<div className='small-details-box ' key={(notes.$key).toString()}>
										<p><Moment format='HH:mm'>{notes.time}</Moment></p>
										{notes.companyName && <p><b>Company:</b> {notes.companyName}</p>}
										{notes.driverName && <p><b>Driver Name:</b> {notes.driverName}</p>}
										{notes.truck && <p><b>Truck #:</b> {notes.truck}</p>}
										{notes.load_type && <p><b>Load Type (Truck):</b> {notes.load_type}</p>}
										{notes.part && <p><b>Rig Part:</b> {notes.part.map((note, index) => {
											return (
												<span className='chips' key={(notes.$key + index).toString()}>
													{note.name ? <span>{note.name}</span> : <span>{note}</span>}
												</span>
											);
										})}</p>}
										{notes.task && <p><b>Task:</b> {notes.task.map((key, index) => {
											return (
												<span key={(notes.$key + index).toString()}>{key}</span>
											);
										})}</p>}
										{notes.load_type && <p><b>Load Type (Trailer):</b> {notes.load_type}</p>}
										{notes.npt && <p><b>Examine NPT:</b> {notes.npt}</p>}
										{notes && notes.permitLoad === true && notes.dimensions.height && (notes.dimensions.height.feet || notes.dimensions.height.inches) &&
											<p>
												<b>Height: </b>
												{notes.dimensions.height.feet && <span>{notes.dimensions.height.feet} ft </span>}
												{notes.dimensions.height.inches && <span>{notes.dimensions.height.inches} inch</span>}
											</p>
										}
										{notes && notes.permitLoad === true && notes.dimensions.width && (notes.dimensions.width.feet || notes.dimensions.width.inches) &&
											<p>
												<b>Width: </b>
												{notes.dimensions.width.feet && <span>{notes.dimensions.width.feet} ft </span>}
												{notes.dimensions.width.inches && <span>{notes.dimensions.width.inches} inch</span>}
											</p>
										}
										{notes && notes.permitLoad === true && notes.dimensions.length && (notes.dimensions.length.feet || notes.dimensions.length.inches) &&
											<p>
												<b>Length: </b>
												{notes.dimensions.length.feet && <span>{notes.dimensions.length.feet} ft </span>}
												{notes.dimensions.length.inches && <span>{notes.dimensions.length.inches} inch</span>}
											</p>
										}
										{notes && notes.permitLoad === true && notes.dimensions.truck && (notes.dimensions.truck.feet || notes.dimensions.truck.inches) &&
											<p>
												<b>Total Overhang on Trailer: </b>
												{notes.dimensions.truck.feet && <span>{notes.dimensions.truck.feet} ft </span>}
												{notes.dimensions.truck.inches && <span>{notes.dimensions.truck.inches} inch</span>}
											</p>
										}
										{notes.axles && <p><b># of Tractor Axles:</b> {notes.tractorAxles}</p>}
										{notes.axles && <p><b># of Trailer Axles:</b> {notes.axles}</p>}
										{notes.stingerAxles && <p className='data-texts'><b># of Stinger Axles:</b> {notes.stingerAxles}</p>}
										{notes.jeepAxles && <p className='data-texts'><b># of Jeep Axles:</b> {notes.jeepAxles}</p>}
										{notes && notes.arrived_location === true &&
											<>
												<p><b>Arrived on Date: </b><Moment format='DD/MM/YYYY HH:mm a'>{notes.arrived_time}</Moment></p>
												<p><b>Arrived on New Location: </b>
													{notes.arrived_diff.days > 0 && <span>{notes.arrived_diff.days} Days </span>}
													<span>{notes.arrived_diff.hour} Hrs {notes.arrived_diff.minutes} Min</span>
												</p>
											</>
										}
										{notes.timer && notes.timer.selectedTime && <p><b>Time Start:</b> {notes.timer.selectedTime}</p>}
										{notes.timer && notes.timer.stoppedTime && <p><b>Time Stop:</b> {notes.timer.stoppedTime}</p>}
										{!notes.timer.start && notes.timer.stoppedTime && <p><b>Load Time:</b> {notes.timer.time}</p>}
										{notes.timer.start && notes.timer.stoppedTime && <p><b>Clock:</b> <span>ON</span></p>}
										{notes.description && <p><b>Description: </b>{notes.description}</p>}
									</div>
								);
							})}
						</div>
					</>
				}

				{/* <---------------------------- RigUp with endDate ----------------------------> */}
				{rigup_notes.length !== 0 && report.endDate &&
					<div className='reportDetails'>
						{rigup_dates.map((date, index) => {
							return (
								<div key={index}>
									<p><Moment format='DD/MM/YYYY'>{date}</Moment></p>
									<div className='small-details-box-row'>
										{rigup_notes.map((notes) => {
											return (
												<div className={notes && date === notes.date ? 'small-details-box displayNotes' : ''} key={(notes.$key).toString()}>
													{notes && date === notes.date &&
														<div>
															<p><b><Moment format='HH:mm'>{notes.time}</Moment></b></p>
															{notes.companyName && <p><b>Company:</b> {notes.companyName}</p>}
															{notes.driverName && <p className='data-texts'><b>Driver Name:</b> {notes.driverName}</p>}
															{notes.truck && <p><b>Truck #:</b> {notes.truck}</p>}
															{notes.load_type && <p><b>Load Type (Truck):</b> {notes.load_type}</p>}
															{notes.part && <p><b>Rig Part:</b> {notes.part.map((note, index) => {
																return (
																	<span className='chips' key={(notes.$key + index).toString()}>
																		{note.name ? <span>{note.name}</span> : <span>{note}</span>}
																	</span>
																);
															})}</p>}
															{notes.task && <p><b>Task:</b> {notes.task.map((key, index) => {
																return (
																	<span key={(notes.$key + index).toString()}>{key}</span>
																);
															})}</p>}
															{notes.load_type && <p className='data-texts'><b>Load Type (Trailer):</b> {notes.load_type}</p>}
															{notes.npt && <p><b>Examine NPT:</b> {notes.npt}</p>}
															{notes && notes.permitLoad === true && notes.dimensions.height && (notes.dimensions.height.feet || notes.dimensions.height.inches) &&
																<p>
																	<b>Height: </b>
																	{notes.dimensions.height.feet && <span>{notes.dimensions.height.feet} ft </span>}
																	{notes.dimensions.height.inches && <span>{notes.dimensions.height.inches} inch</span>}
																</p>
															}
															{notes && notes.permitLoad === true && notes.dimensions.width && (notes.dimensions.width.feet || notes.dimensions.width.inches) &&
																<p>
																	<b>Width: </b>
																	{notes.dimensions.width.feet && <span>{notes.dimensions.width.feet} ft </span>}
																	{notes.dimensions.width.inches && <span>{notes.dimensions.width.inches} inch</span>}
																</p>
															}
															{notes && notes.permitLoad === true && notes.dimensions.length && (notes.dimensions.length.feet || notes.dimensions.length.inches) &&
																<p>
																	<b>Length: </b>
																	{notes.dimensions.length.feet && <span>{notes.dimensions.length.feet} ft </span>}
																	{notes.dimensions.length.inches && <span>{notes.dimensions.length.inches} inch</span>}
																</p>
															}
															{notes && notes.permitLoad === true && notes.dimensions.truck && (notes.dimensions.truck.feet || notes.dimensions.truck.inches) &&
																<p>
																	<b>Total Overhang on Trailer: </b>
																	{notes.dimensions.truck.feet && <span>{notes.dimensions.truck.feet} ft </span>}
																	{notes.dimensions.truck.inches && <span>{notes.dimensions.truck.inches} inch</span>}
																</p>
															}
															{notes.axles && <p className='data-texts'><b># of Tractor Axles:</b> {notes.tractorAxles}</p>}
															{notes.axles && <p className='data-texts'><b># of Trailer Axles:</b> {notes.axles}</p>}
															{notes.stingerAxles && <p className='data-texts'><b># of Stinger Axles:</b> {notes.stingerAxles}</p>}
															{notes.jeepAxles && <p className='data-texts'><b># of Jeep Axles:</b> {notes.jeepAxles}</p>}
															{notes && notes.arrived_location === true &&
																<>
																	<p><b>Arrived on Date: </b><Moment format='DD/MM/YYYY HH:mm a'>{notes.arrived_time}</Moment></p>
																	<p><b>Arrived on New Location: </b>
																		{notes.arrived_diff.days > 0 && <span>{notes.arrived_diff.days} Days </span>}
																		<span>{notes.arrived_diff.hour} Hrs {notes.arrived_diff.minutes} Min</span>
																	</p>
																</>
															}
															{notes.timer && notes.timer.selectedTime && <p><b>Time Start:</b> {notes.timer.selectedTime}</p>}
															{notes.timer && notes.timer.stoppedTime && <p><b>Time Stop:</b> {notes.timer.stoppedTime}</p>}
															{!notes.timer.start && notes.timer.stoppedTime && <p><b>Load Time:</b> {notes.timer.time}</p>}
															{notes.timer.start && notes.timer.stoppedTime && <p><b>Clock:</b> <span>ON</span></p>}
															{notes.description && <p className='data-texts'><b>Description: </b>{notes.description}</p>}
														</div>
													}
												</div>
											);
										})}
									</div>
								</div>
							);
						})}
					</div>
				}
			</Col>
		);
	}

	/* ---------------------------- Load Signatures ---------------------------- */
	renderAllLoads(title, loads, loadDates) {
		// const { loadPickup, loadPickupDates } = this.state;
		return (
			<Col md={24} xl={24} span={24}>
				{loads.length > 0 &&
					<>
						<hr className='report-line' />
						<h6 className='card-title'>{title === 'pickups' ? 'Load Signatures - Load Pickups' : 'Load Signatures - Load Deliveries'}</h6>
						<div className='rig-pic-report'>
							{loadDates.map((dataPic, i) => {
								return (
									<div className='signature-blocks' key={i}>
										<h6 className='date-rig-pics' ><Moment format='MMM DD, YYYY'>{dataPic}</Moment></h6>
										<div className='rig-pics-row'>
											{loads.map((pickup) => {
												return (
													<div className='rig-box' key={pickup.$key} style={{ 'display': (dataPic === pickup.date) ? 'block' : 'none' }}>
														{dataPic === pickup.date ?
															<div className='rig-box-inner'>
																<div className='detail-part'>
																	{pickup.date && <p><b>Date:</b> {pickup.date}</p>}
																	{pickup.accepted && <p><b>Driver accepts:</b> {(pickup.accepted).toString()}</p>}
																	{pickup.declines && <p><b>Declines load:</b> {(pickup.declines).toString()}</p>}
																	{pickup.declineText && <p><b>Decline Reason #:</b> {pickup.declineText}</p>}
																	{pickup.truck && <p><b>Truck #:</b> {pickup.truck}</p>}
																	{pickup.phone && <p><b>Phone #:</b> {pickup.phone}</p>}
																</div>
																<div className='image-part-row'>
																	<div className='image-part'>
																		{pickup.signature ?
																			<>
																				<span>Signature:</span>
																				<img src={pickup.signature} alt='rigpics' />
																			</>
																			:
																			<img src={rec} onLoad={this.onLoad} alt='no_rigpics' />
																		}
																	</div>
																	{pickup.images && pickup.images.map((image, index) => {
																		return (
																			<div className='image-part' key={index}>
																				{image ?
																					<>
																						{title === 'pickups' ? 
																							<span>Capture Picture of Secured Load</span> : 
																							<span>Capture Picture of Delivered Load</span>
																						}
																						<img src={image} alt='rigpics' className='rig-photo' />
																					</>
																					:
																					<img src={rec} alt='no_rigpics' className='rig-photo' />
																				}
																			</div>
																		);
																	})}
																</div>
															</div>
															: null}
													</div>
												);
											})}
										</div>
									</div>
								);
							})}
						</div>
					</>
				}
			</Col>
		);
	}

	/* --------------------------- Assets --------------------------- */
	renderAssets() {
		const { assets, dataAssets, load } = this.state;
		return (
			assets &&
			<Col md={24} xl={24} span={24}>
				<hr className='report-line' />
				{assets.length > 0 ?
					<div>
						<h6 className='card-title'>Assets</h6>
						<div className='reportTable rig-pic-report'>
							{dataAssets.map((dataPic, index) => {
								return (
									<div className='row-main' key={index}>
										<h6 className='date-rig-pics' ><Moment format='MMM DD, YYYY'>{dataPic}</Moment></h6>
										{assets.map((pics, innerIndex) => {
											return (
												<div className='rig-box' key={innerIndex} style={{ 'display': (dataPic === pics.date) ? 'block' : 'none' }}>
													{dataPic === pics.date ?
														<div className='rig-box-inner'>
															<div className='detail-part'>
																{pics.title ? <p><b>Title:</b> {pics.title}</p> : null}
																{pics.date ? <p><b>Date:</b> {pics.date}</p> : null}
																{pics.companyName ? <p><b>Company:</b> {pics.companyName}</p> : null}
																{pics.truck ? <p><b>Truck #:</b> {pics.truck}</p> : null}
																{pics.part ? <p><b>Part:</b> {pics.part}</p> : null}
																{pics.task ? <p><b>Task:</b> {pics.task}</p> : null}
																{pics.description ? <p><b>Description:</b> {pics.description}</p> : null}
																{pics.item_number ? <p><b>Item #:</b> {pics.item_number}</p> : null}
																{pics.asset_number ? <p><b>Asset #:</b> {pics.asset_number}</p> : null}
																{pics.po_number ? <p><b>P.O. #:</b> {pics.po_number}</p> : null}
																{pics.quantity ? <p><b>Enter Quantity:</b> {pics.quantity}</p> : null}
																{pics.height ? <p><b>Enter Height:</b> {pics.height}</p> : null}
																{pics.width ? <p><b>Enter Width:</b> {pics.width}</p> : null}
																{pics.length ? <p><b>Enter Length:</b> {pics.length}</p> : null}
																{pics.weight ? <p><b>Enter Weight:</b> {pics.weight}</p> : null}
																{<p><b>Ready for service: </b>{pics.service === true ? 'true' : 'false'}</p>}
																{<p><b>Needs repairs: </b>{pics.repairs === true ? 'true' : 'false'}</p>}
																{pics.repairDetails && <p><b>Report Details:</b>{(pics.repairDetails).toString()}</p>}
															</div>
															<div className='image-part-row'>
																<div className='image-part'>
																	{load ?
																		<img src={pics.url} alt='rigpics' className='rig-photo' />
																		:
																		<img src={rec} onLoad={this.onLoad} alt='no_rigpics' className='rig-photo' />
																	}
																</div>
															</div>
														</div>
														: null}
												</div>
											);
										})}
									</div>
								);
							})}
						</div>
					</div>
					: null}
			</Col>
		);
	}

	/* --------------------------- Rigpics --------------------------- */
	renderRigPics() {
		const { rigpics, data, load } = this.state;
		return (
			rigpics &&
			<Col md={24} xl={24} span={24}>
				{rigpics.length > 0 &&
					<>
						<hr className='report-line' />
						<h6 className='card-title'>Rig Pics</h6>
						<div className='rig-pic-report'>
							{data.map((dataPic, index) => {
								return (
									<div key={index.toString()}>
										<h6 className='date-rig-pics'><Moment format='MMM DD, YYYY'>{dataPic}</Moment></h6>
										<div className='rig-pics-row'>
											{rigpics.map((pics, innerIndex) => {
												return (
													<div className='rig-box' key={innerIndex.toString()} style={{ 'display': (dataPic === pics.date) ? 'block' : 'none' }}>
														{dataPic === pics.date ?
															<div className='rig-box-inner'>
																<div className='image-part'>
																	{/* Rig images attached in the report */}
																	{load ?
																		<img src={pics.url} alt='rigpics' />
																		:
																		<img src={rec} onLoad={this.onLoad} alt='no_rigpics' />
																	}
																</div>

																{/* description of rigpics in reports */}
																<div className='detail-part'>
																	{pics.title ? <p><b>Title:</b> {pics.title}</p> : null}
																	{pics.date ? <p><b>Date:</b> {pics.date}</p> : null}
																	{pics.companyName ? <p><b>Company:</b> {pics.companyName}</p> : null}
																	{pics.truck ? <p><b>Truck #:</b> {pics.truck}</p> : null}
																	{pics.part ? <p><b>Part:</b> {pics.part}</p> : null}
																	{pics.task ? <p><b>Task:</b> {pics.task}</p> : null}
																	{pics.onSchedule ? <p><b>On Schedule:</b> {(pics.onSchedule).toString()}</p> : null}
																	{pics.npt ? <p><b>Possible Npt:</b> {(pics.npt).toString()}</p> : null}
																	{pics.description ? <p><b>Description:</b> {pics.description}</p> : null}
																</div>
															</div>
															: null}
													</div>
												);
											})}
										</div>
									</div>
								);
							})}
						</div>
					</>
				}
			</Col>
		);
	}

	/* --------------------------- Equipment Inventory --------------------------- */
	renderEquipmentInventory() {
		const { equiList, equiDates } = this.state;
		return (
			equiList &&
			<Col md={24} xl={24} span={24}>
				{equiList.length > 0 &&
					<>
						<hr className="report-line" />
						<h6 className="card-title">Equipment Inventory</h6>
						<div className="rig-pic-report">
							{equiDates.map((dataPic, index) => {
								return (
									<div className='signature-blocks' key={index}>
										<h6 className="date-rig-pics"><Moment format='MMM DD, YYYY'>{dataPic}</Moment></h6>
										<div className="rig-pics-row">
											{equiList.map((pics, innerIndex) => {
												return (
													<div className="rig-box" key={innerIndex} style={{ 'display': (dataPic === pics.date) ? 'block' : 'none' }}>
														{dataPic === pics.date &&
															<div className="rig-box-inner">
																{/* description of equilist in reports */}
																<div className="detail-part report-details">
																	{pics.title ? <p className='data-texts'><b>Title/Name:</b> {pics.title}</p> : null}
																	{pics.companyName ? <p className='data-texts'><b>Company:</b> {pics.companyName}</p> : null}
																	{pics.truck ? <p className='data-texts'><b>Truck #:</b> {pics.truck}</p> : null}
																	{pics.trailer ? <p className='data-texts'><b>Trailer #:</b> {pics.trailer}</p> : null}
																	{pics.driverName ? <p className='data-texts'><b>Driver Name:</b> {pics.driverName}</p> : null}
																	{pics.description ? <p className='data-texts'><b>Description/Notes:</b> {pics.description}</p> : null}
																	{pics.truckCondition ? <p className='data-texts'><b>Truck Condition:</b> {pics.truckCondition}</p> : null}
																	{pics.trailerCondition ? <p className='data-texts'><b>Trailer Condition:</b> {pics.trailerCondition}</p> : null}
																	{pics.chains ? <p className='data-texts'><b># of Chains:</b> {pics.chains}</p> : null}
																	{pics.binders ? <p className='data-texts'><b># of Binders:</b> {pics.binders}</p> : null}
																	{pics.pipeStakes ? <p className='data-texts'><b># of Pipe Stakes:</b> {pics.pipeStakes}</p> : null}
																	{pics.truckTire ? <p className='data-texts'><b>Truck Tire Condition:</b> {pics.truckTire}</p> : null}
																	{pics.trailerTire ? <p className='data-texts'><b>Trailer Tire Condition:</b> {pics.trailerTire}</p> : null}
																	{pics.service ? <p className='data-texts'><b>Ready for service:</b> {(pics.service).toString()}</p> : null}
																	{pics.repairs ? <p className='data-texts'><b>Needs repairs:</b> {(pics.repairs).toString()}</p> : null}
																	{pics.condition ? <p className='data-texts'><b>Overall Condition:</b> {pics.condition}</p> : null}
																</div>

																<div className='image-part-row'>
																	{!pics.images || (pics.images && pics.images.length === 0) ?
																		<></>
																		:
																		pics.images.map((image, i) => {
																			return (
																				<div className='image-part' key={(i + innerIndex)}>
																					{image ?
																						<img src={image} alt='rigpics' className='rig-photo' />
																						:
																						<img src={rec} alt='no_rigpics' className='rig-photo' />
																					}
																				</div>
																			);
																		})
																	}
																</div>
															</div>
														}
													</div>
												);
											})}
										</div>
									</div>
								);
							})}
						</div>
					</>
				}
			</Col>
		);
	}

	render() {
		const { rig, report, user, drilling_summary, cannedSummary, cannedUpSummary, pusherNotes, companies, rigdown_notes, rigup_notes, loadPickup, 
			mastLowered, mastRaised, subsLowered, subsRaised, nippleUpBope, beginBope, finishBope, pickupBha, spud, loadPickupDates, loadDelivery,
			loadDeliveryDates, rigpics, assets, equiList, report : { reportOptions } } = this.state;

		// var nameStyle = { color: 'black' }
		if (rig && report) {
			return (
				<div className='card-body'>
					<div className='report-header'>
						<h3 className='text-center no-mb'>Report Details</h3>
					</div>
					<div className='report-content'>
						<div className='rig-logo'>
							<img src={lightning_logo} alt='rigmove_img' />
						</div>
						<h3 className='report-title-name text-center'>{rig.name}</h3>
						{/* Rig details defined in the particular rig */}
						<div className='details-part'>
							<h6 className='card-title'>RIG #{rig.number} Summary Report</h6>
							<Col md={24} xl={24} span={24}>
								<div className='report-details dataTexts'>
									{/* <p>Date: <Moment format='YYYY/MM/DD'>{report.start}</Moment></p> */}
									<p className='data-texts'><b>Date:</b> <Moment format='DD-MMM-YYYY'>{report.date}</Moment></p>
									{rig.releaseDate ? <p className='data-texts'><b>Start Move:</b> <Moment format='DD-MMM-YYYY'>{rig.releaseDate}</Moment></p> : null}
									<p className='data-texts'><b>Status:</b> {rig.status !== 'Canceled' ? rig.status : 'Cancelled'}</p>
									{(report.reportType === 'los_report' || report.reportType === 'update_report') && rig.jobNumber ?
										<p className='data-texts'><b>Job Number:</b> {rig.jobNumber}</p> : null}
									<p className='data-texts'><b>Rig Name:</b> {rig.name}</p>
									<p className='data-texts'><b>Rig #:</b> {rig.number}</p>
									{rig.afeNumber ? <p className='data-texts'><b>AFE #:</b> {rig.afeNumber}</p> : null}
									{rig.phoneNumber ? <p className='data-texts'><b>Phone Number:</b> {rig.phoneNumber}</p> : null}
									{rig.email ? <p className='data-texts'><b>Contact Email:</b> {rig.email}</p> : null}
									{rig.superintendent ? <p className='data-texts'><b>Superintendent:</b> {rig.superintendent}</p> : null}
									{rig.manager ? <p className='data-texts'><b>Manager:</b> {rig.manager}</p> : null}
									{rig.type ? <p className='data-texts'><b>Rig Type:</b> {rig.type}</p> : null}
									{rig.oldLocationName ? <p className='data-texts'><b>Old Location Name/Well name:</b> {rig.oldLocationName}</p> : null}
									{rig.oldLocation ? <p className='data-texts'><b>GPS Location:</b> {rig.oldLocation}</p> : null}
									{rig.newLocationName ? <p className='data-texts'><b>New Location Name/Well Name:</b> {rig.newLocationName}</p> : null}
									{rig.operator ? <p className='data-texts'><b>Operator:</b> {rig.operator}</p> : null}
									{rig.operatorEmail ? <p className='data-texts'><b>Operator Email:</b> {rig.operatorEmail}</p> : null}
									{rig.operatorPhone ? <p className='data-texts'><b>Operator Phone:</b> {rig.operatorPhone}</p> : null}
									{rig.operatorEngineer ? <p className='data-texts'><b>Operator Drilling Engineer:</b> {rig.operatorEngineer}</p> : null}
									{rig.moveType ? <p className='data-texts'><b>Move Type:</b> {rig.moveType}</p> : null}
									{rig.estMiles ? <p className='data-texts'><b>Est.miles to move:</b> {rig.estMiles}</p> : null}
									{rig.estDays ? <p className='data-texts'><b>Est. days to move:</b> {rig.estDays}</p> : null}
									{rig.actualDaysOfRigmove ? <p className='data-texts'><b>Actual move days:</b> {rig.actualDaysOfRigmove}</p> : null}
									{rig.NptHours ? <p className='data-texts'><b>NPT time:</b> {rig.NptHours}</p> : null}
									{rig.WoNptHours ?
										<p className='data-texts'><b>Move time W/O NPT:</b> {rig.WoNptHours} / {rig.WoNptDays}</p> : null}
									{rig.bumpPlug ? <p className='data-texts'><b>Bump the plug:</b> <Moment format='DD/MM/YY HH:mm'>{rig.bumpPlug.replace('Z', '')}</Moment></p> : null}
									{rig.releaseDate ? <p className='data-texts'><b>Target Release Date:</b> <Moment format='DD/MM/YY HH:mm'>{rig.releaseDate.replace('Z', '')}</Moment></p> : null}
									{rig.firstPick ? <p className='data-texts'><b>First Pick: </b><Moment format='DD/MM/YY HH:mm'>{rig.firstPick.replace('Z', '')}</Moment></p> : null}
									{rig.mastLowered ?
										<p className='data-texts'>
											<b>Mast lowered: </b><Moment format='DD/MM/YY HH:mm'>{rig.mastLowered.replace('Z', '')}</Moment> |
											{mastLowered.days > 0 && <span>{mastLowered.days} day(s)</span>} <span>{mastLowered.hours} hour(s)</span>
										</p> : null}
									{rig.mastRaised ?
										<p className='data-texts'>
											<b>Mast Raised: </b><Moment format='DD/MM/YY HH:mm'>{rig.mastRaised.replace('Z', '')}</Moment> |
											{mastRaised.days > 0 && <span>{mastRaised.days} day(s)</span>} <span>{mastRaised.hours} hour(s)</span>
										</p> : null}
									{rig.subsLowered ?
										<p className='data-texts'>
											<b>Subs lowered: </b><Moment format='DD/MM/YY HH:mm'>{rig.subsLowered.replace('Z', '')}</Moment> |
											{subsLowered.days > 0 && <span>{subsLowered.days} day(s)</span>} <span>{subsLowered.hours} hour(s)</span>
										</p> : null}
									{rig.subsRaised ?
										<p className='data-texts'>
											<b>Subs Raised: </b><Moment format='DD/MM/YY HH:mm'>{rig.subsRaised.replace('Z', '')}</Moment> |
											{subsRaised.days > 0 && <span>{subsRaised.days} day(s)</span>} <span>{subsRaised.hours} hour(s)</span>
										</p> : null}
									{rig.nippleUpBope ?
										<p className='data-texts'>
											<b>Nipple up BOPE:</b> <Moment format='DD/MM/YY HH:mm'>{rig.nippleUpBope.replace('Z', '')}</Moment> |
											{nippleUpBope.days > 0 && <span>{nippleUpBope.days} day(s)</span>} <span>{nippleUpBope.hours} hour(s)</span>
										</p> : null}
									{rig.beginBope ?
										<p className='data-texts'>
											<b>Begin BOPE testing:</b> <Moment format='DD/MM/YY HH:mm'>{rig.beginBope.replace('Z', '')}</Moment> |
											{beginBope.days > 0 && <span>{beginBope.days} day(s)</span>} <span>{beginBope.hours} hour(s)</span>
										</p> : null}
									{rig.finishBope ?
										<p className='data-texts'>
											<b>Finish BOPE testing:</b> <Moment format='DD/MM/YY HH:mm'>{rig.finishBope.replace('Z', '')}</Moment> |
											{finishBope.days > 0 && <span>{finishBope.days} day(s)</span>} <span>{finishBope.hours} hour(s)</span>
										</p> : null}
									{rig.totalBopeDays ? <p className='data-texts'><b>Total Bope Time:</b> {rig.totalBopeDays ? rig.totalBopeDays + ' Days ' + rig.totalBopeHours + ' hour(s)' : rig.totalBopeHours + ' hour(s)'}</p> : null}
									{rig.pickupBha ?
										<p className='data-texts'>
											<b>Pickup BHA:</b> <Moment format='DD/MM/YY HH:mm'>{rig.pickupBha.replace('Z', '')}</Moment> |
											{pickupBha.days > 0 && <span>{pickupBha.days} day(s)</span>} <span>{pickupBha.hours} hour(s)</span>
										</p> : null}
									{rig.spud ?
										<p className='data-texts'>
											<b>Spud:</b> <Moment format='DD/MM/YY HH:mm'>{rig.spud.replace('Z', '')}</Moment> |
											{spud.days > 0 && <span>{spud.days} day(s)</span>} <span>{spud.hours} hour(s)</span>
										</p> : null}
									{rig.mastPickupDays ?
										<p className='data-texts'><b>Mast up to pickup BHA:</b> {rig.mastPickupDays + ' Days ' + rig.mastPickupHours + ' Hour(s)'}</p> : null}
									{rig.releasePickupDays ?
										<p className='data-texts'><b>Release to pickup BHA:</b> {rig.releasePickupDays + ' Days ' + rig.releasePickupHours + ' Hour(s)'}</p> : null}
									{rig.plugBumpDays ?
										<p className='data-texts'><b>Plug bump to pickup BHA:</b> {rig.plugBumpDays + ' Days ' + rig.plugBumpHours + ' Hour(s)'}</p> : null}
									{rig.lastPick ? <p className='data-texts'><b>Last Pick:</b> <Moment format='DD/MM/YY HH:mm'>{rig.lastPick.replace('Z', '')}</Moment></p> : null}
								</div>
								<hr className='report-line' />
							</Col>
							{(cannedSummary.length > 0 || cannedUpSummary.length > 0 || drilling_summary.length > 0) && this.renderTaskMilestones()}
							{pusherNotes.length > 0 && this.renderPusherNotes()}
							{companies.length > 0 && this.renderCompanies()}
							{/* --------------------------- Rigmove Clock --------------------------- */}
							{(rig && ((reportOptions.rigClock && reportOptions.rigClock === true) || (reportOptions.all && reportOptions.all === true))) && this.renderRigmoveClock()}
							{/* --------------------------- LoadCount --------------------------- */}
							{rig && this.renderLoadCount()}
							{rigdown_notes.length > 0 && this.renderRigdownNotes()}
							{rigup_notes.length > 0 && this.renderRigUpNotes()}
							{/* ---------------------------- Load Signatures - Pickup ---------------------------- */}
							{loadPickup.length > 0 && this.renderAllLoads('pickups', loadPickup, loadPickupDates)}
							{/* ---------------------------- Load Signatures - Delivery ---------------------------- */}
							{loadDelivery.length > 0 && this.renderAllLoads('delivery', loadDelivery, loadDeliveryDates)}
							{/* --------------------------- Assets --------------------------- */}
							{assets.length > 0 && this.renderAssets()}
							{/* --------------------------- Rigpics --------------------------- */}
							{rigpics.length > 0 && this.renderRigPics()}
							{/* --------------------------- Equipment Inventory --------------------------- */}
							{equiList.length > 0 && this.renderEquipmentInventory()}
							{/* --------------------------- Current User --------------------------- */}
							<Col md={24} xl={24} span={24}>
								<hr className='report-line' />
								{user &&
									<div className='reportDetails'>
										<p><b>Completed By:</b> {user.firstName} {user.lastName}</p>
										<p><b>Email address:</b> {user.email}</p>
										<p className='p-last-child'><b>Generated on:</b> <Moment format='MMM DD, YYYY'>{report.generatedOn}</Moment> @ <Moment format='h:mm a'>{report.generatedOn}</Moment></p>
									</div>
								}
							</Col>
						</div>
						<div className='copy-right-content'>
							<p>Copyright 2018 - all rights reserved by RigMoveApp</p>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className='loader'>
					<img src={loader} alt='loader' />
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer,
	rig: state.rigReducer
});

export default connect(mapStateToProps)(withRouter(LOSReport));
